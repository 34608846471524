<script>
  import Util from "../../common/Util/Utility";

  export let data;
</script>

<div class="flex justify-end mb-2">
  <div
    class="border border-gray-200 bg-white rounded-md shadow-md w-2/3 flex flex-col p-4 space-y-2"
  >
    {#each data["data"]["items"] as product}
      <div class="flex items-center">
        <h4 class="text-md text-gray-800 font-medium w-3/4 break-words">
          ({product["amount"]})
          <span class="text-primary">{product["name"]}</span>
        </h4>
        <p class="text-md text-gray-800 font-medium w-1/4 text-right">
          {Util.formatRupiah(product["price"], "Rp. ")}
        </p>
      </div>
      {#each product["additionals"] as additionals}
        {#each additionals["items"] as additional}
          <div
            class="border-l-2 border-green-500 flex items-center justify-between pl-2 ml-2"
          >
            <h4 class="text-sm text-gray-800 font-medium">
              {additional["nameParent"] ? additional["nameParent"] : ""} - {additional[
                "name"
              ]}
            </h4>
            <p class="text-md text-green-500">
              + {Util.formatRupiah(additional["price"], "Rp. ")}
            </p>
          </div>
        {/each}
      {/each}
      {#if product["toppings"].length > 0}
        {#each product["toppings"] as topping}
          <div
            class="border-l-2 border-green-500 flex items-center justify-between pl-2 ml-2"
          >
            <h4 class="text-sm text-gray-800 font-medium">
              {topping["name"]}
            </h4>
            <p class="text-md text-green-500">
              + {Util.formatRupiah(topping["price"], "Rp. ")}
            </p>
          </div>
        {/each}
      {/if}
    {/each}
    <hr class="my-3" />
    <div class="flex justify-between items-center">
      <h4 class="text-sm text-gray-800 font-medium">Subtotal</h4>
      <p class="text-md text-gray-800 font-medium">
        {Util.formatRupiah(data["data"]["subtotal"], "Rp. ")}
      </p>
    </div>
    <div class="flex justify-between">
      <h4 class="text-sm text-gray-800 font-medium">Promo Discount</h4>
      <div class="flex flex-col items-end">
        {#if data["data"]["promos"]["promos"].length == 0}
          <p class="text-md text-red-500 font-medium">- Rp. 0</p>
        {/if}
      </div>
    </div>
    {#each data["data"]["promos"]["promos"] as promo}
      <div class="flex justify-between">
        <h4
          class="text-sm text-gray-800 font-medium ml-3 pl-3 border-l-2 border-red-500"
        >
          {promo["promoName"]}
        </h4>
        <div class="flex flex-col items-end w-1/2">
          {#if promo["discountType"] != "BONUS"}
            {#if promo["discount"] != 0}
              <p class="text-md text-red-500 font-medium">
                - {Util.formatRupiah(parseInt(promo["discountValue"]), "Rp. ")}
              </p>
            {/if}
          {/if}
          {#if promo["bonus"].length > 0}
            <div class="flex flex-col space-y-2 items-end break-words-self">
              {#each promo["bonus"] as bonus}
                <p class="text-md text-green-500 font-medium">
                  + Free {bonus["qty"]}
                  {bonus["name"]}
                </p>
              {/each}
            </div>
          {/if}
        </div>
      </div>
    {/each}
    <div class="flex justify-between">
      <h4 class="text-sm text-gray-800 font-medium">Store Discount</h4>
      <div class="flex flex-col items-end">
        <p class="text-md text-red-500 font-medium">
          - {data["data"]["totalStoreDiscount"]
            ? Util.formatRupiah(data["data"]["totalStoreDiscount"], "Rp.")
            : "Rp. 0"}
        </p>
      </div>
    </div>
    <div class="flex justify-between">
      <h4 class="text-sm text-gray-800 font-medium">Artist Discount</h4>
      <div class="flex flex-col items-end">
        <p class="text-md text-red-500 font-medium">
          - {data["data"]["totalArtistDiscount"]
            ? Util.formatRupiah(data["data"]["totalArtistDiscount"], "Rp.")
            : "Rp. 0"}
        </p>
      </div>
    </div>
    <div class="flex justify-between items-center">
      <h4 class="text-sm text-gray-800 font-medium">Service Charge</h4>
      <p class="text-md text-gray-800 font-medium">
        {Util.formatRupiah(parseInt(data["data"]["serviceCharge"]), "Rp. ")}
      </p>
    </div>
    <div class="flex justify-between items-center">
      <h4 class="text-sm text-gray-800 font-medium">Tax</h4>
      <p class="text-md text-gray-800 font-medium">
        {Util.formatRupiah(parseInt(data["data"]["tax"]), "Rp. ")}
      </p>
    </div>
    <div class="flex justify-between items-center">
      <h4 class="text-sm text-gray-800 font-medium">Discount</h4>
      <p class="text-md text-red-500 font-medium">
        - {Util.formatRupiah(
          parseInt(data["data"]["fixDiscount"] || 0),
          "Rp. "
        )}
      </p>
    </div>
    <div class="flex justify-between items-center">
      <h4 class="text-sm text-gray-800 font-medium">Rounding</h4>
      <p class="text-md text-gray-800 font-medium">
        {data["data"]["roundingType"] == "UP"
          ? Util.formatRupiah(parseInt(data["data"]["rounding"]) || 0, "Rp. ")
          : "(" +
            Util.formatRupiah(parseInt(data["data"]["rounding"]) || 0, "Rp. ") +
            ")"}
      </p>
    </div>
    <hr class="my-3" />
    <div class="flex justify-between items-center mb-2">
      <h4 class="text-md text-gray-800 font-medium">Total</h4>
      <p class="text-md text-gray-800 font-medium">
        {Util.formatRupiah(data["data"]["total"], "Rp. ")}
      </p>
    </div>
    <div class="flex justify-between items-center">
      <h4 class="text-md text-gray-800 font-medium w-3/4 break-words">
        {data["payment"]["type"]}
      </h4>
      <p class="text-md text-gray-800 font-medium w-1/4 text-right">
        {Util.formatRupiah(data["payment"]["detail"]["paymentValue"], "Rp. ")}
      </p>
    </div>
    <div class="flex justify-between items-center">
      <h4 class="text-md text-gray-800 font-medium">Kembalian</h4>
      <p class="text-md text-gray-800 font-medium">
        {Util.formatRupiah(
          data["payment"]["detail"]["paymentValue"] - data["data"]["total"],
          "Rp. "
        )}
      </p>
    </div>
  </div>
</div>
