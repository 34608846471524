import { writable } from "svelte/store";
import { connect } from "./connection";
import { writable as wr } from "svelte-local-storage-store";
export const temporders = wr("temporders", []);
// localStorage.clear();
const storedUser = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : null;
const storedOrder = localStorage.getItem("order")
    ? JSON.parse(localStorage.getItem("order") || "[]")
    : null;
export const user = writable(storedUser);
export const order = writable(storedOrder);
export const objOfflineStatus = writable(null);
export const isconnected = writable(false);
export const serverStatus = writable(true);
export const inetStatus = writable(true);
export const currentTransaction = writable(null);
export const onlineOrders = writable([]);
export const paymentTypes = writable([]);
export const currentReport = writable(null);
export const connection = writable(connect());
export const loggedInUserId = writable(null);
export const barcodeScan = wr("barcodeScan", null);
export const store = writable(null);
export const supplies = writable([]);
export const tempStores = writable([]);
export const countOrder = writable(0);
export const userHasLoggedIn = writable(false);
export const currUri = writable(null);
export const tempCart = {
    set: (data) => {
        localStorage.setItem("tempCart", JSON.stringify(data));
    },
    get: () => {
        const data = localStorage.getItem("tempCart");
        return JSON.parse(data);
    },
    clear: () => {
        localStorage.removeItem("tempCart");
    }
};
