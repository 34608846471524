<script lang="ts">
  import Input from "../common/control/Input.svelte";

  import { form as Form } from "svelte-forms";
  import Button from "../common/control/Button.svelte";
  import { replace } from "svelte-spa-router";
  import { user } from "../stores/store";
import { version } from "../config";

  let username = "";
  let email = "";
  let password = "";

  const form = Form(
    () => ({
      username: { value: username, validators: ["required"] },
      password: { value: password, validators: ["required"] },
      email: { value: email, validators: ["required"] },
    }),
    {
      initCheck: false,
      validateOnChange: false,
      stopAtFirstError: false,
      stopAtFirstFieldError: false,
    },
  );

  const handleSubmit = () => {
    form.validate();
    const valid = $form.valid;
    if (valid) {
      const data = {
        id: Math.random(),
        username: username,
        email: email,
        password: password,
      };
      user.subscribe((value) => {
        localStorage.setItem("user", JSON.stringify(data));
      });

      clearInput();
    }
  };

  const clearInput = () => {
    username = "";
    email = "";
    password = "";
  };
</script>

<div
  class="container relative w-full max-w-full mx-auto flex justify-center items-center py-24 px-6 bg-gray-50 h-screen"
>
  <div class="max-w-md w-full p-6 border border-gray-400 font-sans">
    <div class="relative flex flex-wrap">
      <div class="w-full relative">
        <div
          class="p-4 bg-white shadow-lg rounded-full flex items-center justify-center w-40 h-40 absolute -top-28"
          style="left: 50%; margin-left: -75px;"
        >
          <img src="img/logo.png" width="100" alt=".." />
        </div>
        <h4 class="text-center text-xl font-semibold text-dark-second pt-20">
          Point of Sales
        </h4>
        <h4 class="text-center text-3xl font-bold text-dark-second mt-2">
          Kopi Bajawa Flores
        </h4>
        <hr class="my-7 px-5" />
        <div class="flex flex-wrap mb-3 px-10">
          <Input
            label="Email"
            name="email"
            bind:value="{email}"
            form="{form}"
          />
          <div class="mt-4 w-full">
            <Input
              label="Username"
              name="username"
              bind:value="{username}"
              form="{form}"
            />
          </div>
          <div class="mt-4 w-full">
            <Input
              label="Password"
              name="password"
              bind:value="{password}"
              type="password"
              form="{form}"
            />
          </div>
          <Button
            status="primary"
            extClass="w-full mt-10"
            size="large"
            rounded
            on:click="{handleSubmit}"
          >
            Register
          </Button>
          <Button
            status="gray"
            extClass="w-full mt-5"
            size="large"
            appearance="outline"
            rounded
            on:click="{() => replace('/login')}"
          >
            Login
          </Button>
        </div>
      </div>
    </div>
  </div>
  <div class="absolute bottom-0 w-full text-center pb-4">
    <span class="text-md">Copyright 2021. Version {version}</span>
  </div>
</div>
