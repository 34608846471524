<script>
  import Util from "../Util/Utility";
  import { createEventDispatcher, onMount } from "svelte";
  import Button from "../../common/control/Button.svelte";
  const dispatch = createEventDispatcher();

  export let name;
  export let selected;
  export let label = "";
  export let textHint = "Choose";
  export let options = [];
  export let disabled = false;
  export let fullWidth = false;
  export let extClass = "";
</script>

{#if label}
  <label class="block text-primary text-sm font-bold mb-2" for={selected}>
    {label}
  </label>
{/if}
<div class="relative flex space-x-2 items-center">
  <select
    bind:value={selected}
    {name}
    on:change={(e) => {
      selected = e.target.value;
      dispatch("change", selected);
    }}
    class="{extClass
      ? extClass
      : 'flex-1 cursor-pointer block text-sm text-dark-second border dark:text-white dark:bg-dark-third rounded p-2 pr-7 focus:ring-transparent focus:border-gray-800'} {fullWidth
      ? ' w-full '
      : ''}"
    {disabled}
  >
    {#if selected === null || selected === "" || selected === undefined}
      <option class="text-gray-500" selected disabled value={null}>
        {textHint}
      </option>
    {/if}
    {#each options as option}
      {#if selected == option.id}
        <option value={option.id} selected>{option.name}</option>
      {:else}
        <option value={option.id}>{option.name}</option>
      {/if}
    {/each}
  </select>
  <div class="absolute right-2" style="top: 0.7rem;">
    <i class="bx bx-chevron-down bx-xs text-primary" />
  </div>
</div>
