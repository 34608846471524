<script>
	import { createEventDispatcher } from "svelte";
	import { storeDiscType, storeDiscOption } from "../../enums/discountType";
	import { store, loggedInUserId } from "../../stores/store";
	import Button from "../control/Button.svelte";
	import MoneyInput from "../control/MoneyInput.svelte";
	import TextArea from "../control/TextArea.svelte";
	import Modal from "../Modal.svelte";
	import { userActionTitle } from "../../enums/action";
	import userActionService from "../../services/userAction.indexedDB";

	const dispatch = createEventDispatcher();
	export let selectedStoreDiscType;
	export let storeDiscAmountValue;
	export let storeDiscount;
	export let totalStoreDiscount;
	export let remarkStoreDiscount;
	export let storeDiscAmountValueMap;
	export let subTotal;
	export let priceNett;

	let modalStoreDiscount;

	export function open() {
		modalStoreDiscount.toggle();
	}

	export function close() {
		modalStoreDiscount.close();
	}
</script>

<Modal bind:this={modalStoreDiscount} index={20} withTitle={false}>
	<h4 class="mb-4 font-semibold">Store Discount</h4>
	<div class="relative flex flex-wrap items-stretch w-1/4 mb-4">
		<select
			class="flex-1 appearance-none cursor-pointer block text-sm text-dark-second dark:text-white dark:bg-dark-third shadow outline-none rounded p-3 ring-transparent focus:ring-transparent
  focus:border-gray-800 pr-7"
			bind:value={selectedStoreDiscType}
			on:change={() => {
				storeDiscAmountValue = 0;
				storeDiscount = 0;
				totalStoreDiscount = 0;
			}}
		>
			{#each storeDiscOption as option}
				{#if selectedStoreDiscType == option["value"]}
					<option value={option["value"]} selected>{option.name}</option>
				{:else}
					<option value={option["value"]}>{option.name}</option>
				{/if}
			{/each}
		</select>
		<div class="absolute top-2 right-2">
			<i class="bx bx-chevron-down bx-xs text-primary" />
		</div>
	</div>
	{#if selectedStoreDiscType == storeDiscType.Percent.value}
		<div class="grid {$store.discounts.length > 0 ? 'grid-cols-5' : ''} gap-2">
			{#each ($store.discounts || [100]).sort((a, b) => a - b) as storeDisc}
				<div
					class="px-4 py-1 border text-center rounded-md cursor-pointer {storeDiscount == storeDisc ? 'bg-primary text-white' : ''}"
					on:click={() => {
						if (storeDiscount == storeDisc) {
							storeDiscount = 0;
							totalStoreDiscount = 0;
						} else {
							storeDiscount = storeDisc;
						}
					}}
				>
					{storeDisc} %
				</div>
			{/each}
			{#if $store.discounts.length == 0}
				<div class="mb-4 font-semibold text-red-700">This Store does not have any Store Discount with Percent Type.</div>
			{/if}
		</div>
	{:else}
		<div class="grid grid-cols-3 gap-2">
			<div class="flex">
				<MoneyInput name="storeDiscAmount" bind:value={storeDiscAmountValue} />
			</div>
		</div>
	{/if}

	<div class="my-4">
		<TextArea form={null} name="remarks" label="Remarks*" maxlength={200} bind:value={remarkStoreDiscount} placeholder="" />
	</div>
	<div class="w-full flex space-x-2 justify-end">
		<Button
			on:click={() => {
				storeDiscAmountValue = 0;
				remarkStoreDiscount = "";
				storeDiscount = 0;
				totalStoreDiscount = 0;
				modalStoreDiscount.close();
				userActionService.addUserAction(userActionTitle.CloseModalStoreDiscount,$loggedInUserId, {});
			}}
			status="gray">Cancel</Button
		>
		<Button
			size="large"
			status="success"
			on:click={() => {
				if (selectedStoreDiscType == storeDiscType.Percent.value && storeDiscount == 0) {
					globalThis.pushToast("Sorry! Please select one Discount.", "error");
					return;
				}

				if (selectedStoreDiscType == storeDiscType.Amount.value && storeDiscAmountValueMap > subTotal) {
					globalThis.pushToast("Sorry! Discount cannot be more than Sub Total.", "error");
					return;
				}

				if (remarkStoreDiscount.length < 10) {
					globalThis.pushToast("Sorry! Min 10 char Remarks Store Discount.", "error");
					return;
				}

				if (storeDiscount == 100 || storeDiscAmountValueMap == subTotal) {
					totalStoreDiscount = priceNett;
				}
				dispatch("submitStoreDiscount");
				modalStoreDiscount.close();
			}}>Ok</Button
		>
	</div>
</Modal>
