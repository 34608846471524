<script lang="ts">
  import Cookies from "js-cookie";
  import {
    connection,
    objOfflineStatus,
    loggedInUserId,
    user,
    store,
    isconnected,
    temporders as tempOr,
    serverStatus,
    inetStatus,
    temporders,
    paymentTypes,
    barcodeScan,
    userHasLoggedIn,
    tempStores,
    currUri,
  } from "./stores/store";
  import routes from "./routes";
  import Router, { replace } from "svelte-spa-router";
  import "flatpickr/dist/flatpickr.min.css";
  import { onDestroy, onMount } from "svelte";
  import Utility from "../src/common/Util/Utility"

  import { liveQuery } from "dexie";
  import { db } from "./config";

  // let temporders = liveQuery(() => db.temporders.toArray());

  onMount(async () => {});

  $objOfflineStatus = {
    bsAlert: "alert-light",
    statusColor: "",
    status: "offline",
  };

  let userSub;
  let storeSub;
  let onlineOrderSub;
  let promoSub;
  let historySub;
  let suppliesSub;
  let campaignSub;

  // $: con = true;

  $: idStoreSelected = localStorage.getItem("idStore");

  let trueUser;
  let currentUri = window.location.hash;
  let isConnected = true;

  // $: console.log("loggedin userid", $loggedInUserId);
  // $: console.log("connection:", $connection);

  connection.subscribe((server) => {
    (async () => {
      await server.connect();
      // connection is ready here
      const token = Cookies.get("token");
      if (token !== null || token !== undefined) {
        await server.login({ resume: token });
        loggedInUserId.set(server.userId);
        // replace("/");
      }
    })();
    server.on("connected", () => {
      // do something
      console.log("connected");
      isConnected = true;
      isconnected.set(true);
      $serverStatus = true;
      serverStatus.set(true);
      localStorage.setItem("serverConnection", JSON.stringify(true));
      onlineStatus(navigator.onLine, isConnected);
    });
    server.on("disconnected", () => {
      // for example show alert to user
      console.log("disconnected");
      $serverStatus = false;
      serverStatus.set(false);
      onlineStatus(navigator.onLine, isConnected);
    });
    server.on("error", (e) => {
      // global errors from server
      console.error("error:", e);

      $serverStatus = false;
      serverStatus.set(false);
    });
    server.on("login", async (m) => {
      console.log("User logged in as", m);
      loggedInUserId.set(m.id);
      Cookies.set("token", m.token, { expires: 1 });
      isconnected.set(true);
      await afterLogin(m);
    });
    server.on("logout", () => {
      userHasLoggedIn.set(false);
      localStorage.setItem("user", null);
      localStorage.setItem("token", null);
      localStorage.setItem("idStore", null);
      storeSub.stop();
      promoSub.stop();
      campaignSub.stop();
      // onlineOrderSub.stop();
      store.set(null);
      user.set(null);
      loggedInUserId.set(null);
      Cookies.remove("token");
      console.log("User logged out");
      window.location.reload();
    });
    server.on("loginSessionLost", (id) => {
      $serverStatus = false;
      serverStatus.set(false);
      localStorage.setItem("serverConnection", JSON.stringify(false));
      onlineStatus(navigator.onLine, isConnected);
      console.log("you have been lost connection on server!");
    });
    server.on("loginResume", (m) => {
      console.log("User resumed (logged in by token)", m);
      afterLogin(m);
      $serverStatus = true;
      serverStatus.set(true);
    });
    server.on("loginResumeFailed", (m) => {
      console.log(
        "Failed to resume authorization with token after reconnection ",
        m
      );
    });
    async function afterLogin(m) {
      console.log("after login");

      userHasLoggedIn.set(true);

      if ($temporders.length == 0) {
        temporders.set([]);
      }
      barcodeScan.set(null);

      await $connection.call("role.isCurrentInRoles.forPOS").then((e) => {
        trueUser = e;
      });
      promoSub = server.subscribe("promos.forPOS");
      campaignSub = server.subscribe("campaigns.forPOS");
      storeSub = server.subscribe("stores.byLoggedinUser", m.id);
      await storeSub.ready();
      await promoSub.ready();
      let userCollection = server.collection("users").reactive().one();
      let storeCollection = server.collection("locations").reactive();
      let promoCollection = server.collection("promos").reactive();
      let campaignCollection = server.collection("campaigns").reactive();
      let paymentTypeCollection = server.collection("paymentType").reactive();

      user.set(userCollection.data());

      userCollection.onChange((data) => {
        console.log("changed on user");
        user.set(data);
        // localStorage.setItem('user', JSON.stringify(data));
      });
      promoCollection.onChange((data) => {
        console.log("changed on promo");
        localStorage.setItem("promos", JSON.stringify(data));
      });
      campaignCollection.onChange((data) => {
        console.log("changed on campaign");
        localStorage.setItem("campaign", JSON.stringify(data));
      });
      storeCollection.onChange(async (data) => {
        tempStores.set(data);

        if (idStoreSelected) {
          let storeSelected = data.filter((s) => s.id == idStoreSelected);

          await $connection
            .call("stores.products.byId.forPOS", idStoreSelected)
            .then((res) => {
              if (!!res?.products && res?.products?.length > 0) {
                storeSelected[0].products = res.products;
                console.log("store on changed");
                store.update(() => storeSelected[0]);
              }
            });
          if ($serverStatus && $inetStatus) {
            const resPaymentTypes = await $connection.call("paymentTypes.forList");
						let sortedPaymentType = Utility.sortPaymentTypeByCreatedAt(resPaymentTypes);
						paymentTypes.set(sortedPaymentType);
					}
        }
      });
      // transCollection.onChange((data) => {
      //  console.log('changed on transaction');
      //  localStorage.setItem('orders', JSON.stringify(data));
      // });
      // suppliesCollection.onChange((data) => {
      // 	console.log("changed on supplies");
      // 	localStorage.setItem("supplies", JSON.stringify(data));
      //   supplies.set(data);
      // });
      // let tempOrders = JSON.parse(
      // 	localStorage.getItem("temporders") || "[]"
      // );
      // console.log(trueUser);
      if (trueUser) {
        // if (Object.entries(storeCollection.data()).length > 0) {
        tempStores.set(storeCollection.data());
        if (idStoreSelected && idStoreSelected != "null") {
          let storeSelected = storeCollection
            .data()
            .filter((s) => s.id == idStoreSelected);

          await $connection
            .call("stores.products.byId.forPOS", idStoreSelected)
            .then((res) => {
              storeSelected[0].products = res.products;
              storeSelected[0].menus = res.menus;

              store.set(storeSelected[0]);
            });
        }
        if ($serverStatus && $inetStatus) {
          const resPaymentTypes = await $connection.call("paymentTypes.forList");
          let sortedPaymentType = Utility.sortPaymentTypeByCreatedAt(resPaymentTypes);
          paymentTypes.set(sortedPaymentType);
        }
        // $connection
        // .call('transactions.count.byStoreId')
        // .then((e) => {
        //   if(e.message == 'success'){
        //     countOrder.set(e.count + tempOrders.length);
        //   }
        // });
        // localStorage.setItem('store', JSON.stringify(storeCollection.data()));
        // localStorage.setItem(
        //  'orders',
        //  JSON.stringify(transCollection.data()),
        // );
        // supplies.set(suppliesCollection.data());
        // localStorage.setItem(
        // 	"supplies",
        // 	JSON.stringify(suppliesCollection.data())
        // );
        localStorage.setItem("promos", JSON.stringify(promoCollection.data()));
        localStorage.setItem(
          "campaign",
          JSON.stringify(campaignCollection.data())
        );
        // localStorage.setItem(
        //  'products',
        //  JSON.stringify(storeCollection.data().products),
        // );
        const idStoreLocal = localStorage.getItem("idStore");
        console.log("idstore : " + idStoreLocal);
        console.log(currentUri, "currentUri");
        if (
          idStoreLocal == null ||
          idStoreLocal == "null" ||
          idStoreLocal == ""
        ) {
          replace("/mark");
        } else if (!currentUri || currentUri.includes("login")) {
          replace("/");
        } else if (!!$currUri && $currUri?.includes("pos")) {
          // do nothing
        } else {
          replace(currentUri.substring(1, currentUri.length));
        }
      } else {
        replace("/unauthorized");
      }
      // TODO : get data product and save it for POS
      // TODO : if no store or null, ERROR, and show a error page : "You dont have access to any store." dan tombol log out atau mungkin bisa langsung logout dan kasih error di halaman login
    }
  });

  function onlineStatus(blnStatus, connected) {
    if (blnStatus && connected) {
      $objOfflineStatus.statusColor = "text-green-500";
      $objOfflineStatus.status = "online";

      $inetStatus = true;
    } else {
      $objOfflineStatus.statusColor = "text-red-500";
      $objOfflineStatus.status = "offline";

      $inetStatus = false;
    }
  }

  // listen for changes in the network status of the app
  window.addEventListener("load", () => {
    let serverConn = localStorage.getItem("serverConnection");
    if (serverConn) {
      onlineStatus(navigator.onLine, isConnected); // check for online network status when the app loads ...
    }
    if (!navigator.onLine) {
      $inetStatus = false;
      inetStatus.set(false);
    } else {
      $inetStatus = true;
      inetStatus.set(true);
    }

    // ...then listen for changes
    function handleNetworkChange(event) {
      // if (event.type == "online") {
      //   con = true;
      // } else {
      //   con = false;
      // }
      console.log("handleNetworkChange terpanggil", event);
      onlineStatus(navigator.onLine, isConnected);
    }
    window.addEventListener("online", handleNetworkChange); // listen for online and offline event changes
    window.addEventListener("offline", handleNetworkChange);
  });

  window.addEventListener('beforeunload', (e) => {
  	e = e || window.event;

    // For IE and Firefox prior to version 4
    if (e) {
      e.returnValue = "Sure?";
    }

    // For Safari
    return "Sure?";
  });

  function handleConditionFailed(event) {
    if (event.detail.userData.user == undefined) {
      replace("/login");
    }
  }

  $: orders = $tempOr;

  function pingAndSync() {
    setInterval(() => {
      console.log("Jumlah order: ", orders);
      console.log("---------------menghapus bad object--------------");
      let delNull = orders.filter((o) => o != null || o != undefined);
      tempOr.set(delNull);
      console.log("---------------selesai menghapus bad object--------------");
      let dataFiltered = orders.filter(
        (e) => e != null && e != undefined && e.status == "WAITING_FOR_SYNC"
      );
      if (dataFiltered.length > 0) {
        if (dataFiltered[0].data.items.length > 0) {
          console.log(
            "---------------SYNC order start: " +
              dataFiltered[0].data.code +
              " --------------"
          );
          if ($serverStatus && $inetStatus) {
            sync(dataFiltered[0]);
          } else {
            console.log(
              "------------ INTERNET IS OFFLINE , RETRYING LATER --------------"
            );
          }
        } else {
          console.log(
            "---------------DEL order: " +
              dataFiltered[0].data.code +
              "  reason: no data/items--------------"
          );
          let delItems = orders.filter(
            (tmp) => tmp.data.code != dataFiltered[0].data.code
          );
          tempOr.set(delItems);
        }
      }
    }, 10000);
  }

  function sync(order) {
    const { data, customer, payment, id, codeScan } = order;
    const items = data.items.map((item) => {
      return {
        _id: item._id,
        amount: item.amount,
        price: item.price,
        toppings: item.toppings,
        additionals: item.additionals,
        notes: item.notes,
      };
    });
    $connection
      .call(
        "transactions.sync",
        customer,
        data.storeId || data.store._id || data.store.id,
        data.employeeId,
        items,
        payment,
        data.orderNo,
        data.code,
        data.spvDiscount == 0 ? false : true,
        data.storeDiscount,
        data.remarkStoreDiscount,
        data.totalStoreDiscount,
        new Date(data.createdAt),
        codeScan,
        data.ojol,
        data.fixDiscount,
        data.fixDiscountRemark,
        data.voucher,
        data.totalVoucher,
        data.totalArtistDiscount,
        data.remarkPinStore,
        0,
        data.storeDiscountType
      )
      .then((res) => {
        if (res.message == "success") {
          console.log("transaction sucessfully sync with code: " + res.code);
          console.log(
            "---------------DEL order: " +
              res.code +
              " reason: success--------------"
          );
          let afterSync = orders.filter((tmp) => tmp.data.code != res.code);
          tempOr.set(afterSync);
        } else {
          console.log("transaction failure", res.code);
        }
      })
      .catch((err) => {
        console.log("transaction failure sync with code: ", err);
        if (err.error == "transaction-exist") {
          let afterExist = orders.filter((tmp) => tmp.data.code != err.details);
          console.log(
            "---------------DEL order: " +
              err.details +
              " reason: already exist--------------"
          );
          tempOr.set(afterExist);
        }
      });
  }
  setTimeout(() => {
    pingAndSync();
  }, 30000);
</script>

<main>
  <Router {routes} on:conditionsFailed={handleConditionFailed} />
</main>

<style global lang="postcss">
  @tailwind base;
  @tailwind components;
  @tailwind utilities;
  @layer utilities {
		.break-words-self {
			word-break: break-word;
		}
    #scrollbarSide::-webkit-scrollbar-track {
			box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
			border-radius: 10px;
			background-color: #f5f5f5;
		}

		#scrollbarSide::-webkit-scrollbar {
			width: 10px;
			-webkit-overflow-scrolling: touch;
			background-color: #f5f5f5;
		}

		#scrollbarSide::-webkit-scrollbar-thumb {
			border-radius: 10px;
			box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
			background-color: #9c6039;
		}
	}
</style>
