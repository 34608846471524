<script lang="ts">
  import PosTemplate from "../templates/PosTemplate.svelte";
  import Loading from "../common/Loading.svelte";
  import Toast from "../common/Toast.svelte";
  import {
    objOfflineStatus,
    user,
    store,
    connection,
    countOrder,
    temporders as tempOr,
    onlineOrders,
    barcodeScan,
  } from "../stores/store";
  import Layout from "../common/Layout.svelte";
  import Printy from "../common/Util/print";
  import { push, replace } from "svelte-spa-router";
  import { onMount } from "svelte";
  let isLoading;
  $: id = "";

  import { liveQuery } from "dexie";
  import { db } from "../config";
  import TopNotif from "../common/TopNotif.svelte";
  import LostNotif from "../common/LostNotif.svelte";

  // const temporders = liveQuery(() => db.temporders.toArray());

  // $: console.log("PAKET", $temporders);

  onMount(() => {
    if ($store == null) {
      // console.log("on mount dada");
      replace("/");
    }

    // const idStoreLocal = localStorage.getItem("idStore");

    // $connection
    //   .call("onlineOrders.last", idStoreLocal, function (err, res) {
    //     console.log(err);
    //     console.log(res);
    //   })
    //   .then((e) => {
    //     // console.log(e);
    //     onlineOrders.set(e);
    //   });
  });

  function closePrint() {
    document.body.removeChild(this.__container__);
  }

  function setPrint() {
    this.contentWindow.__container__ = this;
    this.contentWindow.onbeforeunload = closePrint;
    this.contentWindow.onafterprint = closePrint;
    this.contentWindow.focus(); // Required for IE
    this.contentWindow.print();
  }

  function printPage(sURL) {
    var oHideFrame = document.createElement("iframe");
    oHideFrame.onload = setPrint;
    oHideFrame.style.position = "fixed";
    oHideFrame.style.right = "0";
    oHideFrame.style.bottom = "0";
    oHideFrame.style.width = "0";
    oHideFrame.style.height = "0";
    oHideFrame.style.border = "0";
    oHideFrame.src = sURL;
    document.body.appendChild(oHideFrame);
  }
</script>

<Loading text="Loading..." bind:this={isLoading} />
{#if $store != null && $store != undefined && $user != null}
  <Layout>
    <PosTemplate/>
    <!-- <TopNotif />
		<LostNotif /> -->
  </Layout>
{/if}
