<script lang="ts">
	import ErrorTemplate from "../templates/ErrorTemplate.svelte";
	export let params = {
		_message: "",
	};
	function getQueryString(query) {
		let param = query.replace("?", "").split("&");
		let result = {};
		let value;
		for (let val of param) {
			if (val.includes("=")) {
				value = val.split("=");
				result[value[0]] = value[1];
			}
		}
		return result;
	}
	let param = getQueryString(params._message) || {};
	let title = "Unauthorized";
	let message =
		param["error"] && param["error"] == "store_not_found"
			? "You don't have access to any store."
			: "You dont have Authorization for access site.";
</script>

<ErrorTemplate {title} {message} />
