<script>
  import Util from "../Util/Utility";
  import { createEventDispatcher } from "svelte";
  const dispatch = createEventDispatcher();

  export let data;
  export let from = null;
  export let hasPrinted = true;

  let phone =
    from == "apps"
      ? data["customer"]["phone"]["number"]
      : data["customer"]["phone"];

  let table = from == "apps" ? data["table"] : data["customer"]["table"];
</script>

<div class="relative mb-2">
  <div class="flex">
    <div
      class="flex-1 p-4 relative border border-gray-200 bg-white shadow-md rounded-tl-lg rounded-bl-lg cursor-pointer hover:bg-gray-100"
    >
      <div class="flex justify-between items-center">
        <h4 class="text-md font-medium text-gray-800">
          Transaction #{from == "apps"
            ? data.code
            : data.code.substring(0, 8).toUpperCase()}
          {#if data.ojol}
            <span class="bg-green-500 rounded-md p-2 text-white text-sm">
              OJOL
            </span>
          {/if}
        </h4>
        <span class="text-sm text-gray-500"
          >{Util.formatDate(
            new Date(data.createdAtOnLocal ?? data.createdAt),
            "long-detail"
          )}</span
        >
      </div>
      <div class="flex justify-between items-center">
        <h4 class="text-sm text-gray-800">
          Order No: <span class="font-medium">{data.orderNo ?? "-"}</span>
        </h4>
      </div>
      <div class="flex justify-between items-center">
        <h4 class="text-sm text-gray-800">
          Status:
          <span
            class="font-medium {data['status'] != 'SUCCESS'
              ? 'text-red-500'
              : 'text-green-500'}">{data["status"]}</span
          >
        </h4>
      </div>
      <div class="flex justify-between items-center">
        <h4 class="text-sm text-gray-800">
          Items ({from == "apps"
            ? data["items"].reduce((sumAdd, itemAdd) => sumAdd + itemAdd.qty, 0)
            : data["items"].reduce(
                (sumAdd, itemAdd) => sumAdd + itemAdd.amount,
                0
              )})
        </h4>
      </div>
      <div class="flex space-x-2 items-center">
        <i class="bx bx-user" />
        <h4 class="text-sm text-gray-800">
          {data["customer"]["name"] ? data["customer"]["name"] : "-"}
        </h4>
      </div>
      <div class="flex space-x-2 items-center">
        <i class="bx bx-phone" />
        <h4 class="text-sm text-gray-800">
          {phone == "" || phone == null ? "-" : phone}
        </h4>
      </div>
      <div class="flex space-x-2 items-center">
        <i class="bx bx-chair" />
        <h4 class="text-sm text-gray-800">
          {table == "" || table == null ? "-" : table}
        </h4>
      </div>
      <p
        class="absolute right-4 bottom-4 text-lg text-gray-800 font-medium m-0"
      >
        {Util.formatRupiah(data["total"], "Rp. ")}
      </p>
    </div>
    {#if hasPrinted}
      <div
        class="bg-primary rounded-tr-lg rounded-br-lg flex items-center justify-center px-4 hover:opacity-90 cursor-pointer"
        on:click={() => {
          dispatch("onprint");
        }}
      >
        <i class="bx bx-printer text-2xl text-white" />
      </div>
    {/if}
  </div>
</div>
