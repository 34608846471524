<script lang="ts">
  import {
    connection,
    loggedInUserId,
    objOfflineStatus,
    userHasLoggedIn,
  } from "../stores/store";
  import LoginTemplate from "../templates/LoginTemplate.svelte";
  import Loading from "../common/Loading.svelte";
  import Layout from "../common/Layout.svelte";
  import LostNotif from "../common/LostNotif.svelte";
  import { onMount } from "svelte";

  let errors;
  let isLoading;

  $: if ($userHasLoggedIn) {
    isLoading.toggle();
  }

  function handleSubmit(e) {
    let user;
    const { password, username } = e.detail;

    if (validateEmail(username)) {
      user = { email: username };
    } else {
      user = { username };
    }
    isLoading.toggle();
    let serverConn = localStorage.getItem("serverConnection");
    if (serverConn == "true") {
      setTimeout(() => {
        $connection
          .login({
            password,
            user,
          })
          .then((user) => {
            loggedInUserId.set(user.userId);
          })
          .catch((err) => {
            errors = [err.reason];
            globalThis.pushToast(errors, "error");
            console.log("error login");
            isLoading.toggle();
          });
      }, 1000);
    } else {
      isLoading.toggle();
      globalThis.pushToast(
        "Sorry! You lost connection on Server now!",
        "error"
      );
    }
  }

  function validateEmail(email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }
</script>

<Loading text="Loading..." bind:this={isLoading} />
<Layout>
  <LoginTemplate on:submit={handleSubmit} />
  <!-- <LostNotif /> -->
</Layout>
