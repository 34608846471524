<script lang="ts">
  import Util from "../common/Util/Utility";
  import { pop, replace, location } from "svelte-spa-router";
  import Button from "../common/control/Button.svelte";
  import { currentReport, paymentTypes, user } from "../stores/store";
	import { transactionReportType } from "../enums/transaction";
	import { excludedPaymentTypeForReport } from "../enums/paymentType";

  function handlePrint() {
    window.print();
  }

  function resolvePriceType(priceType: String) {
    const foundPriceType = transactionReportType.find((it) => it.value == priceType);
    return `Price Type: ${foundPriceType.name}`;
  }

  $: data = $currentReport;

  if (
    $currentReport == null ||
    $currentReport == undefined ||
    $user == null ||
    $user == undefined
  ) {
    replace("/");
  }
</script>

{#if data}
  <div id="screen" class="bg-gray-300 p-7 min-h-screen relative">
    <div class="hide-print fixed top-5 left-5 flex space-x-2">
      <Button status="gray" size="large" on:click={() => pop()}>Back</Button>
      <Button size="large" on:click={() => handlePrint()}>Print</Button>
    </div>
    <div
      id="container"
      class="container bg-white p-2 w-96 mx-auto border border-gray-600 flex flex-col space-y-10"
    >
      <div
        style="font-family: Lucida Console; font-size: 11pt; -webkit-print-color-adjust: exact;"
      >
        <div
          style="text-align:center;white-space:nowrap;overflow:hidden;margin-bottom:10pt;"
        >
          <div
            style="font-size:10pt; white-space:nowrap;overflow:hidden;margin:0;"
          >
            <p style="font-size:13pt; font-weight:bold; margin:0;">
              {$location == "/report/print"
                ? "Summary Sales Report"
                : "Shift Report"}
            </p>
            <p style="font-size:13pt; font-weight:bold;margin:0;">
              {data["store"]["name"]}
            </p>
            <p style="margin:0;">
              From: {Util.formatDate(new Date(data["date"]["from"]), "short")}
            </p>
            <p style="margin:0;">
              To: {Util.formatDate(new Date(data["date"]["to"]), "short")}
            </p>
            <p style="margin:0;">
              {resolvePriceType(data["type"])}
            </p>
            {#if $location == "/report/shiftprint"}
              <p
                style="margin:0; color: 000; font-weight:bold; font-size:12pt;"
              >
                Employee: {$user.profile.name}
              </p>
            {/if}
          </div>
        </div>
        <hr
          style="margin-top:3pt; margin-bottom:3pt; background-color:#000; border-color:#000; color: #000;"
        />
        <p style="font-size:12pt; font-weight:600;margin:0;">Revenue</p>
        <div
          style="display:flex; flex-direction:row; align-items:center; margin-top:3pt;"
        >
          <div style="flex:1;">Sub Total</div>
          <div style="flex:1; text-align:right;">
            {Util.formatRupiah(data["revenue"]["subtotal"], "Rp. ")}
          </div>
        </div>
        <div
          style="display:flex; flex-direction:row; align-items:center; margin-top:3pt;"
        >
          <div style="flex:1;">Promo Discount</div>
          <div style="flex:1; text-align:right;">
            ({Util.formatRupiah(
              Math.round(data["revenue"]["discount"]),
              "Rp. "
            )})
          </div>
        </div>
        <div
          style="display:flex; flex-direction:row; align-items:center; margin-top:3pt;"
        >
          <div style="flex:1;">SPV Discount</div>
          <div style="flex:1; text-align:right;">
            ({Util.formatRupiah(
              Math.round(data["revenue"]["spvDiscount"]),
              "Rp. "
            )})
          </div>
        </div>
        <div
          style="display:flex; flex-direction:row; align-items:center; margin-top:3pt;"
        >
          <div style="flex:1;">Store Discount</div>
          <div style="flex:1; text-align:right;">
            ({Util.formatRupiah(
              Math.round(data["revenue"]["storeDiscount"]),
              "Rp. "
            )})
          </div>
        </div>
        <div
          style="display:flex; flex-direction:row; align-items:center; margin-top:3pt;"
        >
          <div style="flex:1;">Discount</div>
          <div style="flex:1; text-align:right;">
            ({Util.formatRupiah(
              Math.round(data["revenue"]["fixDiscount"] || 0),
              "Rp. "
            )})
          </div>
        </div>
         <div
          style="display:flex; flex-direction:row; align-items:center; margin-top:3pt;"
        >
          <div style="flex:1;">Voucher</div>
          <div style="flex:1; text-align:right;">
            ({Util.formatRupiah(
              Math.round(data["revenue"]["voucherDiscount"] || 0),
              "Rp. "
            )})
          </div>
        </div>
        <div
          style="display:flex; flex-direction:row; align-items:center; margin-top:3pt;"
        >
          <div style="flex:1;">Artist Discount</div>
          <div style="flex:1; text-align:right;">
            ({Util.formatRupiah(Math.round(data["revenue"]["totalArtistDiscount"]), "Rp. ")})
          </div>
        </div>
        <div
          style="display:flex; flex-direction:row; align-items:center; margin-top:3pt;"
        >
          <div style="flex:1;">Net Sales</div>
          <div style="flex:1; text-align:right;">
            {Util.formatRupiah(Math.round(data["revenue"]["netsales"]), "Rp. ")}
          </div>
        </div>
        <div
          style="display:flex; flex-direction:row; align-items:center; margin-top:3pt;"
        >
          <div style="flex:1;">Services Charge</div>
          <div style="flex:1; text-align:right;">
            {Util.formatRupiah(
              Math.round(data["revenue"]["serviceCharge"]),
              "Rp. "
            )}
          </div>
        </div>
        <div
          style="display:flex; flex-direction:row; align-items:center; margin-top:3pt;"
        >
          <div style="flex:1;">Tax</div>
          <div style="flex:1; text-align:right;">
            {Util.formatRupiah(Math.round(data["revenue"]["tax1"]), "Rp. ")}
          </div>
        </div>
        <div
          style="display:flex; flex-direction:row; align-items:center; margin-top:3pt;"
        >
          <div style="flex:1;">Rounding</div>
          <div style="flex:1; text-align:right;">
						{ data["revenue"]["rounding"] >= 0
							? Util.formatRupiah(Math.round(data["revenue"]["rounding"]), "Rp. ")
							: "(" + Util.formatRupiah(Math.abs(Math.round(data["revenue"]["rounding"])), "Rp. ") + ")"}
					</div>
        </div>
        <hr
          style="margin-top:3pt; margin-bottom:3pt; background-color:#000; border-color:#000; color: #000;"
        />
        <div
          style="display:flex; flex-direction:row; align-items:center; margin-top:3pt;"
        >
          <div style="flex:1; font-weight:bold;">Total</div>
          <div style="flex:1; text-align:right; font-weight:bold;">
            {Util.formatRupiah(Math.round(data["revenue"]["total"]), "Rp. ")}
          </div>
        </div>
        <p style="font-size:12pt; font-weight:600;margin:0;margin-top:6pt;">
          Payment
        </p>
        {#each $paymentTypes.filter((e) => !excludedPaymentTypeForReport.includes(e.code)) as payment}
          <div
            style="display:flex; flex-direction:row; align-items:center; margin-top:3pt;"
          >
            <div style="flex:1;">{payment["name"]}</div>
            <div style="flex:1; text-align:right;">
              {Util.formatRupiah(
                parseInt(data["payment"][payment["code"]]),
                "Rp. "
              )}
            </div>
          </div>
        {/each}
        <hr
          style="margin-top:3pt; margin-bottom:3pt; background-color:#000; border-color:#000; color: #000;"
        />
        <div
          style="display:flex; flex-direction:row; align-items:center; margin-top:3pt;"
        >
          <div style="flex:1; font-weight:bold;">Total</div>
          <div style="flex:1; text-align:right; font-weight:bold;">
            {Util.formatRupiah(data["totalPayment"], "Rp. ")}
          </div>
        </div>
        <p style="font-size:12pt; font-weight:600;margin:0;margin-top:6pt;">
          Profit & Loss
        </p>
        <div
          style="display:flex; flex-direction:row; align-items:center; margin-top:3pt;"
        >
          <div style="flex:1;">Sub Total</div>
          <div style="flex:1; text-align:right;">
            {Util.formatRupiah(data["profit"]["subtotal"], "Rp. ")}
          </div>
        </div>
        <div
          style="display:flex; flex-direction:row; align-items:center; margin-top:3pt;"
        >
          <div style="flex:1;">Promo Discount</div>
          <div style="flex:1; text-align:right;">
            ({Util.formatRupiah(
              Math.round(data["profit"]["discount"]),
              "Rp. "
            )})
          </div>
        </div>
        <div
          style="display:flex; flex-direction:row; align-items:center; margin-top:3pt;"
        >
          <div style="flex:1;">SPV Discount</div>
          <div style="flex:1; text-align:right;">
            ({Util.formatRupiah(
              Math.round(data["profit"]["spvDiscount"]),
              "Rp. "
            )})
          </div>
        </div>
        <div
          style="display:flex; flex-direction:row; align-items:center; margin-top:3pt;"
        >
          <div style="flex:1;">Store Discount</div>
          <div style="flex:1; text-align:right;">
            ({Util.formatRupiah(
              Math.round(data["profit"]["storeDiscount"]),
              "Rp. "
            )})
          </div>
        </div>
        <div
          style="display:flex; flex-direction:row; align-items:center; margin-top:3pt;"
        >
          <div style="flex:1;">Discount</div>
          <div style="flex:1; text-align:right;">
            ({Util.formatRupiah(
              Math.round(data["profit"]["fixDiscount"] || 0),
              "Rp. "
            )})
          </div>
        </div>
        <div
          style="display:flex; flex-direction:row; align-items:center; margin-top:3pt;"
        >
          <div style="flex:1;">Voucher Discount</div>
          <div style="flex:1; text-align:right;">
            ({Util.formatRupiah(Math.round(data["profit"]["voucherDiscount"]), "Rp. ")})
          </div>
        </div>
        <div
          style="display:flex; flex-direction:row; align-items:center; margin-top:3pt;"
        >
          <div style="flex:1;">Artist Discount</div>
          <div style="flex:1; text-align:right;">
            {Util.formatRupiah(Math.round(data["profit"]["totalArtistDiscount"]), "Rp. ")}
          </div>
        </div>
        <div
          style="display:flex; flex-direction:row; align-items:center; margin-top:3pt;"
        >
          <div style="flex:1;">Net Sales</div>
          <div style="flex:1; text-align:right;">
            {Util.formatRupiah(Math.round(data["profit"]["netsales"]), "Rp. ")}
          </div>
        </div>
        <div
          style="display:flex; flex-direction:row; align-items:center; margin-top:3pt;"
        >
          <div style="flex:1;">Profit</div>
          <div style="flex:1; text-align:right;">
            {Util.formatRupiah(Math.round(data["profit"]["profit"]), "Rp. ")}
          </div>
        </div>
        <p style="font-size:12pt; font-weight:600;margin:0;margin-top:6pt;">
          Bonus
        </p>
        <div
          style="display:flex; flex-direction:row; align-items:center; margin-top:3pt;"
        >
          <div style="flex:1;">Total Cost</div>
          <div style="flex:1; text-align:right;">
            {Util.formatRupiah(Math.round(data["bonusTotal"] || 0), "Rp. ")}
          </div>
        </div>
        <p style="font-size:12pt; font-weight:600;margin:0;margin-top:6pt;">
          Product Category
        </p>
        {#each data["category"] as category}
          <div
            style="display:flex; flex-direction:row; align-items:center; margin-top:3pt;"
          >
            <div style="margin-right:12pt;">{category["qty"]}</div>
            <div style="flex:1;">{category["name"]}</div>
            <div style="flex:1; text-align:right;">
              {Util.formatRupiah(Math.round(category["total"]), "Rp. ")}
            </div>
          </div>
        {/each}
        <hr
          style="margin-top:3pt; margin-bottom:3pt; background-color:#000; border-color:#000; color: #000;"
        />
        <div
          style="display:flex; flex-direction:row; align-items:center; margin-top:3pt;"
        >
          <div style="flex:1; font-weight:bold;">Total</div>
          <div style="flex:1; text-align:right; font-weight:bold;">
            {Util.formatRupiah(
              Math.round(data["category"].reduce((a, b) => a + b["total"], 0)),
              "Rp. "
            )}
          </div>
        </div>
        <p style="font-size:12pt; font-weight:600;margin:0;margin-top:6pt;">
          Statistic
        </p>
        <div
          style="display:flex; flex-direction:row; align-items:center; margin-top:3pt;"
        >
          <div style="flex:1;">Total Sales</div>
          <div style="flex:1; text-align:right;">
            {Util.formatRupiah(Math.round(data["statistic"]["sales"]), "Rp. ")}
          </div>
        </div>
        <div
          style="display:flex; flex-direction:row; align-items:center; margin-top:3pt;"
        >
          <div style="flex:1;">Total Invoice</div>
          <div style="flex:1; text-align:right;">
            {Util.formatRupiah(Math.round(data["statistic"]["invoice"]))}
          </div>
        </div>
        <div
          style="display:flex; flex-direction:row; align-items:center; margin-top:3pt;"
        >
          <div style="flex:1;">Avg Amount per Invoice</div>
          <div style="flex:1; text-align:right;">
            {Util.formatRupiah(
              data["statistic"]["avgInvoice"].toFixed(0),
              "Rp. "
            )}
          </div>
        </div>
        <div style="width:100%; font-size:11pt; color: #000;" />
        <footer style="margin-top:10pt; text-align:center; font-size:11pt;">
          Generated {Util.formatDate(new Date(), "long-detail")}
          <br />
          == End of Report ==
        </footer>
      </div>
    </div>
  </div>
{/if}

<style>
  @media screen {
    .hide-on-screen {
      display: none;
    }
  }
  @media print {
    #screen {
      padding: 0;
    }
    #container {
      margin: 0;
      padding: 0;
      width: 100%;
      border: none;
    }
    .hide-print {
      display: none;
    }
    @page {
      margin: 0.5rem;
      size: 3.5in 3.5in;
      size: portrait;
    }
  }
</style>
