<script>
  export let nBar = 1;
</script>

<div
  role="status"
  class="pt-4 space-y-8 animate-pulse md:space-y-0 md:space-x-8 rtl:space-x-reverse md:flex md:items-center"
>
  <div class="w-full">
    {#each {length: nBar} as _, i}
      <div class="h-4 bg-gray-200 rounded-full dark:bg-gray-700 mb-4"></div>
    {/each}
  </div>
</div>
