<script>
  import { createEventDispatcher } from "svelte";
  const dispatch = createEventDispatcher();
  import Util from "../Util/Utility";
  import Button from "../control/Button.svelte";

  export let item;
</script>

<div class="flex items-center">
  <h4 class="text-md font-medium flex-1 text-gray-800 w-3/4 break-words">
    {item.name}
  </h4>
  <div class="flex items-center">
    <p class="text-md font-medium text-gray-800">
      {Util.formatRupiah(item.price, "Rp. ")}
    </p>
  </div>
</div>
{#if !!item["isErrorMenu"]}
  <p class="text-red-500 text-xs italic">Menu is not Available</p>
{/if}
{#if !!item["isErrorProduct"]}
  <p class="text-red-500 text-xs italic">Product is not Available</p>
{/if}
<div class="flex justify-between items-center mt-1">
  <div class="flex space-x-2">
    <Button
      extClass={!!item.isErrorRequiredModifier && "border-red-500 border-2"}
      size="small"
      on:click={() => {
        dispatch("edit");
      }}>Edit</Button
    >
    <Button
      size="small"
      on:click={() => {
        dispatch("notes");
      }}>CO Notes</Button
    >
    <!-- <div class="px-1"></div> -->
    <!-- <Button
        status="success"
        size="small"
        on:click="{() =>
          globalThis.pushToast('under development', 'error')}"
        >Campaign</Button
      > -->
  </div>
  <div class="flex items-center">
    <div
      class="flex flex-wrap w-full relative h-15 bg-white items-center rounded"
    >
      <div
        class="flex -mr-px justify-center w-15 p-2 {item.qty == 1
          ? 'bg-red-500 '
          : 'bg-gray-200 '} rounded-tl-md rounded-bl-md"
      >
        <span
          on:click={() => dispatch("minus")}
          class="flex items-center leading-normal {item.qty == 1
            ? 'bg-red-500 text-white'
            : 'bg-gray-200 text-gray-600'} text-xl whitespace-no-wrap cursor-pointer"
        >
          {#if item.qty == 1}
            <i class="bx bx-trash"></i>
          {:else}
            <i class="bx bx-minus"></i>
          {/if}
        </span>
      </div>
      <input
        value={item.qty}
        type="text"
        maxlength="4"
        class="flex-shrink flex-grow leading-normal w-16 text-center flex-1 border-0 h-10 pb-1 relative self-center text-xl outline-none"
        placeholder="1"
        disabled
      />
      <div
        class="flex -mr-px justify-center w-15 p-2 rounded-tr-md rounded-br-md bg-gray-200"
      >
        <span
          on:click={() => dispatch("plus")}
          class="flex items-center leading-normal bg-gray-200 text-xl whitespace-no-wrap text-gray-600 cursor-pointer"
        >
          <i class="bx bx-plus"></i>
        </span>
      </div>
    </div>
  </div>
</div>
{#if item.isErrorRequiredModifier}
  <p class="text-red-500 text-xs italic">Modifier wajib dipilih</p>
{/if}
<div class="flex flex-col mt-2">
  {#each item.additionals as additional}
    <div class="border-l-2 border-primary pl-3 py-3">
      <div class="flex justify-between">
        <h4 class="text-sm font-medium flex-1 text-gray-800">
          {additional["nameParent"]} - {additional["name"]}
        </h4>
        <p class="text-sm font-medium text-gray-800">
          {Util.formatRupiah(additional["price"], "Rp. ")}
        </p>
      </div>
      {#if !!additional["isErrorModifier"]}
        <p class="text-red-500 text-xs italic">Modifier is not Available</p>
      {/if}
    </div>
  {/each}
  {#each item.toppings as topping}
    <div class="border-l-2 border-primary pl-3 py-3 flex justify-between">
      <h4 class="text-sm font-medium flex-1 text-gray-800">
        {topping["name"]}
      </h4>
      <p class="text-sm font-medium text-gray-800">
        {Util.formatRupiah(topping["price"], "Rp. ")}
      </p>
    </div>
    {#if !!topping["isErrorTopping"]}
      <p class="text-red-500 text-xs italic">Topping is not Available</p>
    {/if}
  {/each}
</div>
{#if item.notes != ""}
  <div class="flex items-center bg-gray-100 space-x-2 rounded-lg p-2">
    <i class="bx bxs-note text-lg text-gray-800"></i>
    <p class="flex-1 text-sm text-gray-800 break-words overflow-hidden">
      {item.notes}
    </p>
  </div>
{/if}
