<script lang="ts">
	import Cookies from 'js-cookie';
	import {
		connection,
		loggedInUserId,
		objOfflineStatus,
		user,
	} from '../stores/store';
	import { link, replace } from 'svelte-spa-router';
	export let title = "";
	export let message = "";
import { version } from '../config';
</script>

<div
	class="container relative w-full max-w-full mx-auto flex justify-center items-center py-24 px-6 bg-gray-50 h-screen my-32 md:my-0"
>
	<div class="max-w-5xl w-full px-6 font-sans">
		<div class="relative flex flex-wrap">
			<div class="w-full relative text-center">
				<p class="text-4xl font-bold">{title}</p>
				<p>{message}</p>
				<div class="flex mt-7 flex-wrap"> </div>
			</div>
		</div>
	</div>
	<div class="absolute top-0 right-0 w-full text-right pr-4 pt-4">
		<button
			on:click="{() => {
				if ($objOfflineStatus.status == 'online') {
					$connection.logout();
					loggedInUserId.set(null);
					replace('/login');
				} else {
					alert('you cannot close this window.');
				}
			}}"
			class="text-md font-medium">Logout</button
		>
	</div>
	<div class="absolute bottom-0 w-full text-center pb-4">
		<div class="p-1 m-2 mt-4"
			>Network status: <strong class="{$objOfflineStatus.statusColor}"
				>{$objOfflineStatus.status}</strong
			></div
		>
		<span class="text-md">Copyright 2021. Version {version}</span>
	</div>
</div>
