<script>
	import { onMount } from "svelte";
	import { connection, paymentTypes, store, serverStatus, inetStatus } from "../stores/store";
	import Input from "./control/Input.svelte";
	import { form as Form } from "svelte-forms";
	import { excludedPaymentType, paymentTypeEnum, paymentTypeStatus } from "../enums/paymentType";
	import Utility from "./Util/Utility";

	export let moreExcludedPaymentType = [];
	export let paymentType = "";
	export let paymentDetail = {
		name: "",
		numberCard: null,
		cvv: null,
		expDate: null,
	};
	export let title = "Payment Type";
	export let titleColor = "text-dark-second";
	export let hasDefaultPaymentType = true;
	let combinedExcludedPaymentType = [...excludedPaymentType];
	let masterPaymentType = [];
	let storeData = {};
	const formKredit = Form(
		() => ({
			paymentDetail: { value: paymentDetail, validation: ["required"] },
		}),
		{
			initCheck: false,
			validateOnChange: false,
			stopAtFirstError: false,
			stopAtFirstFieldError: false,
		}
	);
	onMount(() => {
		if ($serverStatus && $inetStatus) {
			onLoadForOnline();
		} else {
			onLoadForOffline();
		}
	});
	let filteredPaymentTypes = [];

	const loadMasterAndStorePaymentType = async () => {
		try {
			const storeId = $store.id || $store._id;
			const _res = await $connection.call("paymentTypes.forStore", storeId);
			let sortedPaymentType = Utility.sortPaymentTypeByCreatedAt(_res.masterPaymentType);

			masterPaymentType = sortedPaymentType;
			paymentTypes.set(sortedPaymentType);
			storeData = _res.storeData;
		} catch (error) {
			globalThis.pushToast("Something went wrong!", "error");
			console.log(error);
		}
	};

	const filterPaymentTypes = () => {
		filteredPaymentTypes = masterPaymentType.filter(
			(e) =>
				!combinedExcludedPaymentType.includes(e.code) &&
				e.status == paymentTypeStatus.Active &&
				Utility.resolveActiveInPosValue(e, storeData)
		);
		if (hasDefaultPaymentType && filteredPaymentTypes.length > 0) {
			paymentType = filteredPaymentTypes[0].code;
		}
	};

	const onLoadForOnline = async () => {
		combineExcludedPaymentTypes();
		await loadMasterAndStorePaymentType();
		filterPaymentTypes();
	};

	const onLoadForOffline = async () => {
		combineExcludedPaymentTypes();
		masterPaymentType = $paymentTypes;
		storeData = $store;
		filterPaymentTypes();
	};

	const combineExcludedPaymentTypes = () => {
		if (moreExcludedPaymentType && Array.isArray(moreExcludedPaymentType)) {
			combinedExcludedPaymentType = [...excludedPaymentType, ...moreExcludedPaymentType];
		}
	};

	export function refreshData() {
		onLoadForOnline();
	}

	function resolvePaymentTypeColor(paymentTypeFromList, selectedPaymentType) {
		const colorClass = paymentTypeFromList.colorClass;
		const colorBaseClass = colorClass ? colorClass.split("-")[0] : "";

		const isSelected = paymentTypeFromList.code === selectedPaymentType;
		if (isSelected) {
			const isSelectedBaseClass = "text-white border-4";
			return colorBaseClass
				? `bg-${colorBaseClass}-900 ${isSelectedBaseClass} border-black`
				: `bg-primary ${isSelectedBaseClass} border-primary`;
		}

		return colorBaseClass ? `bg-${colorClass} text-white border-2 border-${colorClass}` : " border-2 border-primary";
	}
</script>

<div class="flex flex-col">
	{#if $store}
		<div class="font-bold {titleColor} mb-2 text-xs uppercase">{title}</div>
		<div class="grid grid-cols-1 xl:grid-cols-3 gap-3">
			{#each filteredPaymentTypes as payment}
				<div
					class="font-semibold rounded-full px-5 py-1 h-8 flex justify-center items-center {resolvePaymentTypeColor(
						payment,
						paymentType
					)} cursor-pointer"
					on:click={() => {
						paymentType = payment["code"];
					}}
				>
					<span class="truncate">
						{payment["name"]}
					</span>
				</div>
			{/each}
		</div>
	{/if}
	{#if paymentType.includes(paymentTypeEnum.Credit.code) || paymentType.includes(paymentTypeEnum.Debit.code)}
		<div class="flex flex-col mt-5 space-y-2">
			<Input form={formKredit} name="credit name" label="Name on card" bind:value={paymentDetail.name} />
			<Input
				form={formKredit}
				name="card number"
				type="creditcard"
				label="Card Number (6 Last Number)"
				placeholder="XXX XXX"
				bind:value={paymentDetail.numberCard}
			/>
			<div class="flex space-x-2">
				<Input
					form={formKredit}
					name="exp date"
					type="expdate"
					label="Expired Date"
					placeholder="MM/YY"
					bind:value={paymentDetail.expDate}
				/>
				<!-- <Input
						  form="{formKredit}"
						  name="cvv"
						  type="cvv"
						  label="CVV"
						  placeholder="000"
						  maxlength="{3}"
						  bind:value="{paymentDetail.cvv}"
					  /> -->
			</div>
		</div>
	{/if}
</div>
