<script lang="ts">
	import Layout from "../common/Layout.svelte";
	import Loading from "../common/Loading.svelte";
	import { userActionTitle } from "../enums/action";
	import userActionService from "../services/userAction.indexedDB";
	import { connection, objOfflineStatus, user, loggedInUserId } from "../stores/store";
	import AccountTemplate from "../templates/AccountTemplate.svelte";

	let _name;
	let _gender;
	let _image = "https://bit.ly/3pPY1jc";
	let modalLoading;
	user.subscribe((u) => {
		if (u) {
			_name = u.profile?.name;
			_gender = u.profile?.gender;
			_image = u.profile?.image ? u.profile?.image : "https://bit.ly/3pPY1jc";
		}
	});

	const updateUser = (e) => {
		const { fullname, gender, image } = e.detail;

		_name = fullname;
		_gender = gender;
		_image = image;

		if ($objOfflineStatus.status == "online") {
			$connection
				.call("users.update.profile", image, fullname, gender)
				.then(() => {
					globalThis.pushToast("Successfully Update Account.", "success");
					const updatedUser = {
						name: fullname,
						gender,
						image,
					};
					userActionService.addUserAction(userActionTitle.UpdateAccount, $loggedInUserId, updatedUser);
				})
				.catch((err) => {
					console.log(err);
				});
		}
	};
</script>

<Loading text="Loading..." bind:this={modalLoading} />

<Layout isFooterAbsolute={false}>
	<AccountTemplate
		bind:fullname={_name}
		gender={_gender}
		image={_image}
		on:updateUser={updateUser}
	/>
</Layout>
