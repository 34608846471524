export const transactionType = {
    ALL: { id: "all", name: "All Prices" },
    NORMAL: { id: "normal", name: "Normal Price" },
    OJOL: { id: "ojol", name: "Ojol Price" },
};
export const transactionReportType = [
    { id: 0, name: "All", value: "all" },
    { id: 1, name: "POS Normal Price", value: "pos_normal" },
    { id: 2, name: "POS Ojol Price", value: "pos_ojol" },
    { id: 3, name: "All POS", value: "only_pos" },
    { id: 4, name: "Mobile Payment", value: "only_mobile" },
    { id: 5, name: "Mobile COD", value: "cod_mobile" },
];
export const transactionStatus = {
    Success: "SUCCESS",
    WaitingForPayment: "WAITING_FOR_PAYMENT",
};
export const transactionTypeArr = Object.values(transactionType);
