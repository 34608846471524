export const updatedProductActionType = {
    Additional: "additional",
    Topping: "topping",
};
export const actionOrigin = {
    POS: "POS",
    ChangePaymentType: "ChangePaymentType",
};
export const userActionTitle = {
    // SETTINGS
    ClickSettingsOnDashboard: "Click Settings menu on Dashboard page",
    UpdateAccount: "Click Save button on the Account section on Settings page",
    TogglePaymentTypeStatus: "Toggle payment type status on Payment Type Configuration section on Settings page",
    // POS
    ClickPOSOnDashboard: "Click POS menu on Dashboard page",
    ToggleOjolPrice: "Toggle ojol price on POS page",
    ProcessTransactionWhenOnline: "Click Pay on Modal Payment on POS page on Online mode",
    ProcessTransactionWhenOffline: "Click Pay on Modal Payment on POS page on Offline mode",
    ClickRefreshOnPOS: "Click Refresh button on POS page",
    // POS - PRODUCT
    ClickProduct: "Click a product on POS page",
    AddProductToCartFromPOS: "Click Add To Cart button on Modal Add Product on POS page",
    AddProductToCartFromMobile: "Add a product to cart from Mobile on POS page",
    CloseModalProduct: "Close Modal Add/Edit Product on POS page",
    ClickEditOnCartProduct: "Click Edit button on a product in cart on POS page",
    EditCartProduct: "Click Ok button on Modal Edit Product on POS page",
    // POS - PRODUCT - NOTES
    ClickNotesOnCartProduct: "Click CO Notes button on a product in cart on POS page",
    SaveNotesOnCartProduct: "Click Save button on Modal Note on POS page",
    CloseModalNote: "Close Modal Note on POS page",
    // POS - PRODUCT - REMOVE
    ClickRemoveOnCartProduct: "Click Trash icon on a product in cart on POS page",
    RemoveCartProduct: "Click Yes button on Modal Delete Product on POS page",
    CloseModalDeleteProduct: "Close Modal Delete Product on POS page",
    // POS - STORE DISCOUNT
    ClickStoreDiscount: "Click Edit icon on Store Discount section on POS page",
    AddStoreDiscount: "Click Ok button on Modal Store Discount on POS page",
    CloseModalStoreDiscount: "Close Modal Store Discount on POS page",
    RemoveStoreDiscount: "Click Trash icon on Store Discount section on POS page",
    // POS - ARTIST DISCOUNT
    ClickArtistDiscount: "Click Edit icon on Artist Discount section on POS page",
    AddArtistDiscount: "Click Ok button on Modal Artist Discount on POS page",
    CloseModalArtistDiscount: "Close Modal Artist Discount on POS page",
    RemoveArtistDiscount: "Click Trash icon on Artist Discount section on POS page",
    // POS - HISTORY
    ClickHistoryOnPOS: "Click History button on POS page",
    ClickHistoryOnPOSOffline: "Click History button on POS page while in offline mode",
    ClickPrintOnHistoryOnPos: "Click Print icon on Modal History on POS page",
    CloseModalHistoryOnPOS: "Close Modal History on POS page",
    // POS - SYNC
    ClickSyncOnPOS: "Click Sync button on POS page",
    ClickPrintOnSyncOnPos: "Click Print icon on Modal Sync on POS page",
    CloseModalSyncOnPOS: "Close Modal Sync on POS page",
    // POS - PRINT
    ClickPrintCOOnModalPrintOnModalHistoryOnPOS: "Click Print CO button on Modal Print on Modal History on POS page",
    ClickPrintCOOnModalPrintOnModalSyncOnPOS: "Click Print CO button on Modal Print on Modal Sync on POS page",
    ClickPrintCOOnModalPrintAfterPayment: "Click Print CO button on Modal Print after payment on POS page",
    ClickPrintReceiptOnModalPrintOnHistoryPOS: "Click Print Receipt on Modal History on POS page",
    ClickPrintReceiptOnModalPrintOnSyncPOS: "Click Print Receipt on Modal Sync on POS page",
    ClickPrintReceiptOnModalPrintAfterPayment: "Click Print Receipt button on Modal Print after payment on POS page",
    CloseModalPrintOnPOS: "Close Modal Print on POS page",
    // POS - SCAN BARCODE
    ClickScanBarcode: "Click Scan icon on POS page",
    SubmitBarcode: "Click Submit on Modal Scan Barcode on POS page",
    CloseModalScanBarcode: "Close Modal Scan Barcode on POS page",
    // HISTORY
    ClickHistoryOnDashboard: "Click History menu on Dashboard page",
    ClickRefreshOnHistory: "Click Refresh button on History page",
    // HISTORY - CHANGE PAYMENT TYPE
    ClickChangePaymentType: "Click Change Payment Type button on a transaction on History page",
    RequestChangePaymentType: "Click Save button on Modal Change Payment Type on History page",
    CloseModalChangePaymentType: "Close Modal Change Payment Type on History page",
    // HISTORY - VOID
    ClickVoid: "Click Void button on a transaction on History page",
    RequestVoid: "Click Save button on Modal Void on History page",
    CloseModalVoid: "Close Modal Void on History page",
    // HISTORY - RECHECK
    ClickRecheck: "Click Recheck button on History page",
    RecheckTransaction: "Click Submit button on Modal Recheck on History page",
    CloseModalRecheck: "Close Modal Recheck on History page",
    // HISTORY - PRINT
    ClickPrintOnHistory: "Click Print icon on a transaction on History page",
    ClickPrintCOOnModalPrintOnHistory: "Click Print CO button on Modal Print on History page",
    ClickPrintReceiptOnModalPrintOnHistory: "Click Print Receipt on History page",
    CloseModalPrintOnHistory: "Close Modal Print on History page",
    // MENU
    ClickMenuOnDashboard: "Click Menu on Dashboard page",
    ClickRefreshOnMenu: "Click Refresh button on Menu page",
    // MENU - MENU
    ToggleMenuStatus: "Toggle menu status on Menu page",
    ToggleProductInMenuActiveInPOS: "Toggle product's activeInPOS status on Menu tab on Menu page",
    ToggleProductInMenuActiveInApp: "Toggle product's activeInApp status on Menu tab on Menu page",
    // MENU - PRODUCT
    ToggleProductActiveInPOS: "Toggle product's activeInPOS status on Product tab on Menu page",
    ToggleProductActiveInApp: "Toggle product's activeInApp status on Product tab on Menu page",
    // MENU - MODIFIER
    ToggleModifierParentStatus: "Toggle modifier's parent's status on Modifier tab on Menu page",
    ToggleModifierActiveInPOS: "Toggle modifier's activeInPOS status on Modifier tab on Menu page",
    ToggleModifierActiveInApp: "Toggle modifier's activeInApp status on Modifier tab on Menu page",
    // SYNC
    BeforeSync: "Before Syncing a transaction",
    SucceededSync: "Succeed in Syncing a transaction",
    FailedSync: "Failed in Syncing a transaction",
    TransactionExistWhenSync: "Transaction already exist in database when trying to sync",
    ErrorWhenSync: "An error occured when trying to sync",
};
