<script lang="ts">
  import { onMount } from "svelte";
  import { replace } from "svelte-spa-router";
  import { Tab, TabList, TabPanel, Tabs } from "svelte-tabs";
  import ExpandableMenu from "../common/ExpandableMenu.svelte";
  import Loading from "../common/Loading.svelte";
  import TopNotif from "../common/TopNotif.svelte";
  import Search from "../common/control/Search.svelte";
  import { connection, serverStatus, store, inetStatus } from "../stores/store";
	import SimpleTable from "../common/table/SimpleTable.svelte";

  let items = JSON.parse(localStorage.getItem("promos") || "[]");

  let storedata = $store;
  let storeAllProducts = [];
  let storeProducts = [];
  let storeAllMenus = [];
  let storeMenus = [];
  let storeAllModif = [];
  let storeModif = [];
  let searchProduct = "";
  let searchMenu = "";
  let searchModif = "";
  let modalLoading;
  const tableHeaders = [
		{ tableHeader: "Nama Produk", propertyName: "name", width: "w-4/5", headerFontWeight: "font-bold", headerFontColor: "text-black" },
		{
			tableHeader: "Pos",
			propertyName: "activeInPos",
			isSwitch: true,
			switchId: 'ActiveInPos',
			textAlign: "text-center",
			headerFontWeight: "font-extrabold",
      headerFontColor: "text-black"
		},
		{
			tableHeader: "Mobile",
			propertyName: "activeInApp",
			isSwitch: true,
			switchId: 'ActiveInApp',
			textAlign: "text-center",
			headerFontWeight: "font-extrabold",
      headerFontColor: "text-black"
		},
	];
  onMount(() => {
    reloadData();
  });

  const formatMenu = (_menus = []) => {
    // formatting menu to make update status in expandable component more easier
    return _menus.map((item) => {
      return {
        ...item,
        status: item.status == "ACTIVE" ? true : false,
      };
    });
  };

  // return an array of menus that is processed so that it can be used in component
  const resolveMenu = (_menus = [], _products = []) => {
    return _menus.map((menu) => {
      const newProducts = [];
      for (const menpro of menu.products) {
        const foundProduct = _products.find((pro) => pro._id == menpro._id);
        if (foundProduct) {
          newProducts.push({
            ...menpro,
            activeInApp: foundProduct.activeInApp,
            activeInPos: foundProduct.activeInPos,
          });
        }
      }

      return {
        ...menu,
        products: newProducts,
      };
    });
  };

  // return an array of modifiers that is processed so that it can be used in component
  const resolveModifier = (_products = []) => {
    const newAdds = [];
    for (const pro of _products) {
      for (const adds of pro.additionals) {
        const foundAdds = newAdds?.findIndex((it) => it._id == adds._id);
        if (foundAdds == -1) {
          newAdds.push({
            ...adds,
            products: adds.items.map((item) => {
              return {
                ...item,
                parentId: adds._id,
                parentChildId: `${adds._id}-${item.code}`,
              };
            }),
          });
        }
      }
    }

    return newAdds;
  };

  function updateStatusProduct(product, type, tabType) {
    modalLoading.toggle();
    let status =
      type == "pos"
        ? product.activeInPos
        : type == "menus"
          ? product.status
          : product.activeInApp;

    $connection
      .call(
        "stores.products.update.status",
        storedata.id,
        product._id,
        status,
        type
      )
      .then((f) => {
        handleAfterUpdateData(tabType);
      })
      .catch((err) => {
        globalThis.pushToast(err.reason, "error");
        modalLoading.toggle();
      });
  }

  const updateStatusModifier = (product, type) => {
    modalLoading.toggle();
    let status =
      type == "pos"
        ? product.activeInPos
        : type == "menus"
          ? product.status
          : product.activeInApp;

    $connection
      .call(
        "stores.productAdditionals.update.status",
        storedata.id,
        type == "menus" ? product._id : product.parentChildId,
        status,
        type
      )
      .then((f) => {
        handleAfterUpdateData("modifier");
      })
      .catch((err) => {
        globalThis.pushToast(err.reason, "error");
        modalLoading.toggle();
      });
  };

  const handleAfterUpdateData = async (tabType) => {
    await reloadData();
    if (tabType == "menu")
        globalThis.pushToast("Successfully Changed Menu", "success");
      else if (tabType == "product")
        globalThis.pushToast("Successfully Changed Product", "success");
      else if (tabType == "modifier")
        globalThis.pushToast("Successfully Changed Modifier", "success");

      searchProduct = "";
      searchMenu = "";
      searchModif = "";
      search("menu");
      search("product");
      search("modif");
      modalLoading.toggle();
  };

  const reloadData = async () => {
    const idStoreSelected = localStorage.getItem("idStore");
    await $connection
      .call("stores.products.byId.forPOS", idStoreSelected)
      .then((res) => {
        const _productStore =
          res.products != null &&
          res.products != undefined &&
          res.products.length > 0
            ? res.products
            : [];

        const _menuStore =
          res.menus != null && res.menus != undefined && res.menus.length > 0
            ? res.menus
            : [];

        storeProducts = _productStore;
        storeAllProducts = storeProducts;

        storeMenus = resolveMenu(_menuStore, storeAllProducts);
        storeAllMenus = storeMenus;

        storeModif = resolveModifier(_productStore);
        storeAllModif = storeModif;
      });
  };

  const search = (type) => {
    if (type == "menu") {
      const keyword = searchMenu.trim().toLowerCase();
      if (keyword == "") {
        storeMenus = storeAllMenus;
        return;
      }
      let dataSearchFiltered = [];

      storeAllMenus.forEach((menu) => {
        let newProducts = [];
        menu.products.forEach((prod) => {
          if (prod.name.toLowerCase().includes(keyword)) {
            newProducts.push(prod);
          }
        });
        if (newProducts.length > 0) {
          dataSearchFiltered.push({
            ...menu,
            products: newProducts,
          });
        }
      });
      storeMenus = dataSearchFiltered;
    } else if (type == "product") {
      const keyword = searchProduct.trim().toLowerCase();
      storeProducts = storeAllProducts.filter((pro) => {
        return pro.name.toLowerCase().includes(keyword);
      });
    } else if (type == "modif") {
      const keyword = searchModif.trim().toLowerCase();

      let dataSearchFiltered = [];

      storeAllModif.forEach((parent) => {
        let newModif = [];
        parent.products.forEach((child) => {
          const _parentName = parent.name.toLowerCase();
          const _childName = child.name.toLowerCase();
          if (`${_parentName} - ${_childName}`.includes(keyword)) {
            newModif.push(child);
          }
        });
        if (newModif.length > 0) {
          dataSearchFiltered.push({
            ...parent,
            products: newModif,
          });
        }
      });
      storeModif = dataSearchFiltered;
    }
  };

  const resolveDisable = (_menus = [], product) => {
    if (_menus.length > 0) {
      const formattedMenu = formatMenu(_menus);
      const activeMenu = formattedMenu.filter((item) => !!item.status);
      // if there is at least 1 active menu,  disable POS and Mobile switch on product tab
      if (activeMenu.length > 0) {
        return { menu: false, product: true };
      } else {
        // disable menu's switch if there is no active menu and
        // disable Mobile switch on product tab if the product is not active in pos
        return { menu: true, product: !!product && !product.activeInPos };
      }
    }

    return { menu: false, product: !!product && !product.activeInPos};
  };
</script>

<Loading text="Loading..." bind:this={modalLoading} />

<div class="relative p-4">
  <div class="flex relative items-center z-10 cursor-pointer">
    <div class="flex relative items-center" on:click={() => replace("/")}>
      <i class="bx bxs-chevron-left text-3xl" />
      <h5 class="ml-2 text-md font-medium text-dark-second">Home</h5>
    </div>
    <div
			class="relative ml-4 flex items-center justify-center bg-primary text-white px-4 py-1 rounded-md cursor-pointer
      {$inetStatus && $serverStatus ? 'cursor-pointer' : 'opacity-50 cursor-not-allowed'}"
			on:click={() => {
				reloadData();
			}}
		>
			<i class="bx bx-refresh text-xl mr-1" />
			<span>Refresh</span>
		</div>
  </div>
  <h4
    class="absolute z-0 top-5 left-0 right-0 text-center text-md font-medium text-dark-second"
  >
    Menu
  </h4>

  <div class="mt-5">
    {#if storeAllMenus.length == 0 && storeAllProducts.length == 0}
      <div class="relative p-4 mb-2">
        <div class="flex justify-between items-center">
          <h4 class="text-md font-medium text-gray-800">
            No Menu & Product Found
          </h4>
        </div>
      </div>
    {:else}
      <Tabs>
        <TabList>
          <Tab>Menus</Tab>
          <Tab>Products</Tab>
          <Tab>Modifier</Tab>
        </TabList>

        <TabPanel>
					<Search bind:value={searchMenu} on:search={() => search("menu")} />
          {#if storeMenus.length == 0}
            <div class="relative border border-gray-300 p-4 mb-2 mt-6 mx-4">
              <div class="flex justify-between items-center">
                <h4 class="text-md font-medium text-gray-800">
                  Menu not found.
                </h4>
              </div>
            </div>
          {:else}
            <div class="flex flex-col md:flex-row mt-6">
              <div class="w-full mx-auto px-4">
                <ExpandableMenu
                  data={formatMenu(storeMenus)}
                  disabledAll={resolveDisable(storeAllMenus).menu}
                  on:select={(e) => {
                    updateStatusProduct(e.detail, "menus", "menu");
                  }}
                  on:posActive={(e) => {
                    if (!resolveDisable(storeAllMenus).menu) {
                      updateStatusProduct(e.detail, "pos", "menu");
                    }
                  }}
                  on:appActive={(e) => {
                    if (!resolveDisable(storeAllMenus).menu) {
                      updateStatusProduct(e.detail, "mobile", "menu");
                    }
                  }}
                />
              </div>
            </div>
          {/if}
        </TabPanel>

        <TabPanel>
					<Search bind:value={searchProduct} on:search={() => search("product")} />
          {#if storeProducts.length == 0}
            <div class="relative border border-gray-300 p-4 mb-2 mt-6 mx-4">
              <div class="flex justify-between items-center">
                <h4 class="text-md font-medium text-gray-800">
                  Product not found.
                </h4>
              </div>
            </div>
          {:else}
            <div class=" mt-5 px-5">
              <SimpleTable
								headers={tableHeaders}
								noDataMessage={"Product not found"}
								tableData={storeProducts.map((item) => {
									return {
										...item,
										isDisabledActiveInPos: resolveDisable(storeAllMenus).product,
										isDisabledActiveInApp: resolveDisable(storeAllMenus, item).product,
									};
								})}
								on:switchActiveInPos={(e) => {
									updateStatusProduct(e.detail, "pos", "product");
								}}
								on:switchActiveInApp={(e) => {
									updateStatusProduct(e.detail, "mobile", "product");
								}}
							/>
            </div>
          {/if}
        </TabPanel>

        <TabPanel>
					<Search bind:value={searchModif} on:search={() => search("modif")} />
          {#if storeModif.length == 0}
            <div class="relative border border-gray-300 p-4 mb-2 mt-6 mx-4">
              <div class="flex justify-between items-center">
                <h4 class="text-md font-medium text-gray-800">
                  Modifier not found.
                </h4>
              </div>
            </div>
          {:else}
            <div class="flex flex-col md:flex-row mt-6">
              <div class="w-full mx-auto px-4">
                <ExpandableMenu
                  data={formatMenu(storeModif)}
                  disabledAll={false}
                  on:select={(e) => {
                    updateStatusModifier(e.detail, "menus");
                  }}
                  on:posActive={(e) => {
                    updateStatusModifier(e.detail, "pos");
                  }}
                  on:appActive={(e) => {
                    updateStatusModifier(e.detail, "mobile");
                  }}
                />
              </div>
            </div>
          {/if}
        </TabPanel>
      </Tabs>
    {/if}
  </div>

  <TopNotif />
</div>
