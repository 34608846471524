export function imageUrlValid(str) {
    let imageUrl = "";
    let noImg = "https://bit.ly/3pPY1jc";
    if (str != noImg && str != "") {
        let splitUrl = str.split("/");
        imageUrl =
            splitUrl[0] +
                splitUrl[1] +
                "//" +
                splitUrl[2] +
                "/" +
                splitUrl[3] +
                "/tr:w-100/" +
                splitUrl[4];
    }
    else if (str != "") {
        imageUrl = str;
    }
    else {
        imageUrl = noImg;
    }
    return imageUrl;
}
