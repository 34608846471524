<script lang="ts">
  import { onMount } from "svelte";
  import { replace } from "svelte-spa-router";
  import FilterCard from "../common/card/FilterCard.svelte";
  import Loading from "../common/Loading.svelte";
  import Modal from "../common/Modal.svelte";
  import Printy from "../common/Util/print";
  import PrintyCo from "../common/Util/printCo";
  import Util from "../common/Util/Utility";

  import SyncCard from "../common/card/SyncCard.svelte";
  import SyncDetailCard from "../common/card/SyncDetailCard.svelte";
  import { transactionType } from "../enums/transaction";
  import {
  	connection,
  	store,
  	temporders as tempOr
  } from "../stores/store";

  let searchKeyword = "";
  let searchDate = "";
  let sectionId = "";
  let isLoading;

  let modalPrint;
  let tempTransaction = {};

  $: items = $tempOr.filter((ord) => ord).reverse();

  let priceSelect = transactionType.ALL.id;

  onMount(async () => {
    search();
  });

  const groups =
    items != undefined
      ? items
          .filter((item) =>
            item["data"]["employeeId"] == $connection.userId &&
            priceSelect == transactionType.OJOL.id
              ? items["ojol"] == true
              : priceSelect == transactionType.NORMAL.id
                ? items["ojol"] == null || items["ojol"] == false
                : true
          )
          .reduce((groups, item) => {
            const date = item["data"].createdAtOnLocal.split("T")[0];
            if (!groups[date]) {
              groups[date] = [];
            }
            groups[date].push(item);
            return groups;
          }, {})
      : [];

  const groupMap =
    items != undefined
      ? Object.keys(groups).map((date) => {
          return {
            date,
            items: groups[date],
          };
        })
      : [];

  let dataFiltered = groupMap || [];

  const search = () => {
    const keyword = searchKeyword.trim().toLowerCase();
    const date = searchDate;

    const newgroups = items
      .filter((item) => item && item.data)
      .filter((item) => item["data"]["employeeId"] == $connection.userId)
      .reduce((groups, item) => {
        const date = new Date(item["data"]["createdAtOnLocal"]).toDateString();

        if (!groups[date]) {
          groups[date] = [];
        }
        groups[date].push(item);
        return groups;
      }, {});

    dataFiltered = Object.keys(newgroups).map((date) => {
      return {
        date,
        items: newgroups[date],
      };
    });

    let itemFiltered = dataFiltered;

    if (keyword) {
      itemFiltered = dataFiltered
        .filter((item) =>
          item.items.find((it) =>
            it.data.code.toString().toLowerCase().includes(keyword)
          )
        )
        .map((item) => {
          return {
            date: item.date,
            items: item.items.filter((it) =>
              it.data.code.toString().toLowerCase().includes(keyword)
            ),
          };
        });
    }

    if (keyword && date) {
      itemFiltered = itemFiltered.filter((item) => {
        if (new Date(date).toDateString() == new Date(item.date).toDateString())
          return true;

        return false;
      });
    } else if (date) {
      itemFiltered = dataFiltered.filter((item) => {
        if (new Date(date).toDateString() == new Date(item.date).toDateString())
          return true;

        return false;
      });
    }    
    if (priceSelect != transactionType.ALL.id) {
			itemFiltered = itemFiltered.map((item) => {
				const filteredItems = item.items.filter((it) => {
					return priceSelect === transactionType.OJOL.id ? !!it.data.ojol : !it.data.ojol;
				});

				return {
					...item,
					items: filteredItems,
				};
			});
    }
    dataFiltered = [...itemFiltered];
  };
</script>

<Loading text="Loading..." bind:this={isLoading} />

<!-- Modal Print  -->
<Modal
  title="Confirm"
  bind:this={modalPrint}
  index={20}
  confirm
  withTitle={false}
  modalPrint
  confirmText="Want Print ?"
  on:printCo={() =>
    PrintyCo(
      $store,
      tempTransaction["items"],
      tempTransaction,
      tempTransaction["promos"]
    )}
  on:print={() =>
    Printy(
      $store,
      tempTransaction["items"],
      tempTransaction,
      tempTransaction["promos"]
    )}
  on:cancel={() => {
    modalPrint.toggle();
  }}
/>

<div class="relative p-4">
  <div
    class="flex relative items-center z-10 cursor-pointer"
    on:click={() => replace("/")}
  >
    <i class="bx bxs-chevron-left text-3xl" />
    <h5 class="ml-2 text-md font-medium text-dark-second">Home</h5>
  </div>
  <h4
    class="absolute z-0 top-5 left-0 right-0 text-center text-md font-medium text-dark-second"
  >
    Sync Data Transaction
  </h4>

  <div class="flex flex-wrap justify-center items-center my-14">
    <div class="max-w-2xl w-full flex flex-col justify-center items-center">
      <div
        class="flex items-center sm:flex-col space-y-4 md:space-x-4 md:space-y-0 w-full"
      >
        <!-- Filter Section  -->
        <FilterCard
          bind:searchText={searchKeyword}
          bind:selectedTransactionType={priceSelect}
          bind:selectedDate={searchDate}
          on:changeFilter={search}
        />
      </div>

      <div class="w-full mt-4">
        {#if dataFiltered.length === 0}
          <div class="relative border border-gray-300 p-4 mb-2">
            <div class="flex justify-between items-center">
              <h4 class="text-md font-medium text-gray-800">
                No Transaction Found.
              </h4>
            </div>
          </div>
        {/if}

        <!-- List Section  -->
        {#each dataFiltered as item}
          <h4 class="text-md text-dark-second font-medium mb-2">
            {Util.formatDate(new Date(item.date), "short")}
          </h4>
          {#each item["items"] as data}
            <SyncCard
              {data}
              on:click={() => {
                if (sectionId == data["data"]["id"]) {
                  sectionId = "";
                } else {
                  sectionId = data["data"]["id"];
                }
              }}
              on:print={() => {
                tempTransaction = {};
                tempTransaction = data["data"];
                tempTransaction = tempTransaction;
                modalPrint.toggle();
              }}
            />
            {#if sectionId === data["data"]["id"]}
              <SyncDetailCard {data} />
            {/if}
          {/each}
        {/each}
      </div>
    </div>
  </div>
</div>
