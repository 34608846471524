<script>
	import "cleave.js/dist/addons/cleave-phone.id";
	import { createEventDispatcher } from "svelte";

	export let placeholder = "Search Product Name..";
	export let value = "";
	const dispatch = createEventDispatcher();
</script>

<div class="relative flex align-center w-full mb-2 mt-4 px-4">
	<span
		class="z-10 h-full leading-snug font-normal text-center text-gray-400 absolute bg-transparent text-base flex items-center justify-center w-8 pl-3 py-2"
	>
		<i class="bx bx-search" />
	</span>
	<input
		bind:value
		on:input={() => dispatch("search", value)}
		type="text"
		{placeholder}
		class="appearance-none block pl-10 pr-10 w-full text-dark-second border dark:text-white dark:bg-dark-third rounded-full py-2 px-3 focus:ring-transparent focus:border-gray-800 outline-none"
	/>
	<span
		on:click={() => {
			value = "";
      dispatch("search", value);
		}}
		class="z-10 h-full leading-snug font-normal text-center text-gray-400 absolute right-0 bg-transparent text-base flex items-center justify-center w-8 pr-10 py-2 cursor-pointer"
	>
		<i class="bx bx-x" />
	</span>
</div>
