<script>
	import { createEventDispatcher } from "svelte";
	import Button from "../control/Button.svelte";
	import { each } from "svelte/internal";
	import Switch from "../control/Switch.svelte";
	const dispatch = createEventDispatcher();

	export let tableData;
	export let noDataMessage = "No Data Available";
	export let headers = [];
	export let useWrapText = false;

	function resolveStyle(data, header) {
		let returnedStyle = "";
		if (header.isColor) {
			returnedStyle += data[`${header.propertyName}Color`] + " ";
		}

		if (header.fontWeight) {
			returnedStyle += header.fontWeight + " ";
		}

		if (header.textAlign) {
			returnedStyle += header.textAlign + " ";
		} else {
			returnedStyle += "text-left ";
		}
		return returnedStyle.trim();
	}

	function resolveHeaderStyle(header) {
		let returnedStyle = "";
		if (header.headerFontWeight) {
			returnedStyle += header.headerFontWeight + " ";
		}

		if (header.headerFontColor) {
			returnedStyle += header.headerFontColor + " ";
		} else {
			returnedStyle += "text-gray-500 ";
		}

		if (header.textAlign) {
			returnedStyle += header.textAlign + " ";
		} else {
			returnedStyle += "text-left ";
		}

		if (header.width) returnedStyle += header.width + " ";

		return returnedStyle.trim();
	}
</script>

<table class="min-w-full divide-y divide-gray-200" style={useWrapText && "table-layout: fixed;"}>
	<thead class="bg-gray-50">
		<tr>
			{#each headers as header}
				<th scope="col" class="px-6 py-3 text-left text-md font-medium {resolveHeaderStyle(header)}">
					{header.tableHeader}
				</th>
			{/each}
		</tr>
	</thead>
	<tbody class="bg-white divide-y divide-gray-200 overflow-x-auto">
		{#if tableData.length == 0}
			<tr>
				<td colspan="6" class="px-6 py-4 whitespace-nowrap text-md text-gray-500">
					{noDataMessage}
				</td></tr
			>
		{/if}
		{#each tableData as data, index}
			<tr>
				{#each headers as header}
					{#if header.isButton}
						<td class="px-6 py-4 {useWrapText ? '' : 'whitespace-nowrap'} text-left text-md text-gray-800 flex space-x-2">
							{#each header.actions as action}
								{#if data[`isShow${action.actionId}`]}
									<Button
										extClass={"h-7"}
										status={action.buttonStatus}
										leftIcon={action.buttonLeftIcon ?? ""}
										on:click={() => {
											dispatch(`click${action.actionId}`, data);
										}}>{action.buttonText ?? ""}</Button
									>
								{/if}
							{/each}
						</td>
					{:else if header.isSwitch}
						<td class="text-center">
							<Switch
								value={data[header.propertyName]}
								checked={data[header.propertyName]}
								name={`switch${header.switchId}`}
								notText
								on:change={(e) => {
									const isDisabled = data[`isDisabled${header.switchId}`];
									if (!isDisabled) {
										data[header.propertyName] = !data[header.propertyName];
										dispatch(`switch${header.switchId}`, data);
									}
								}}
								disabled={data[`isDisabled${header.switchId}`]}
							/>
						</td>
					{:else if header.propertyName == "index"}
						<td class="px-6 py-4 whitespace-nowrap text-sm text-gray-800 {resolveStyle(data, header)}">
							{index + 1}
						</td>
					{:else}
						<td class="px-6 py-4 {useWrapText ? '' : 'whitespace-nowrap'} text-sm text-gray-800 {resolveStyle(data, header)}">
							{data[header.propertyName]}
						</td>
					{/if}
				{/each}
			</tr>
		{/each}
	</tbody>
</table>
