<script lang="ts">
  import { format } from "date-fns";
  import { createEventDispatcher } from "svelte";
  import { form as Form } from "svelte-forms";
  import { replace } from "svelte-spa-router";
  import Loading from "../common/Loading.svelte";
  import Modal from "../common/Modal.svelte";
  import Util from "../common/Util/Utility";
  import Printy from "../common/Util/print";
  import PrintyCo from "../common/Util/printCo";
  import FilterCard from "../common/card/FilterCard.svelte";
  import HistoryCard from "../common/card/HistoryCard.svelte";
  import HistoryDetailCard from "../common/card/HistoryDetailCard.svelte";
  import TransactionCard from "../common/card/TransactionCard.svelte";
  import TransactionDetailCard from "../common/card/TransactionDetailCard.svelte";
  import Button from "../common/control/Button.svelte";
  import Input from "../common/control/Input.svelte";
  import TextArea from "../common/control/TextArea.svelte";
  import ModalChangePaymentType from "../common/modal/ModalChangePaymentType.svelte";
  import { printType } from "../enums/print";
  import { transactionStatus, transactionType, transactionTypeArr } from "../enums/transaction";
  import { connection, loggedInUserId, store, inetStatus, serverStatus, paymentTypes } from "../stores/store";

  const dispatch = createEventDispatcher();

  let searchKeyword = "";
  let searchDate = format(new Date(), "yyyy-MM-dd");
  let sectionId = "";
  let sectionData;

  let modalVoid;
  let modalChangePaymentType;
  let voidRemark = "";
  let modalPrint;
  let tempTransaction = {};
  let modalRecheck;
  let codeRecheck = "";
  let isLoading;
  let modalOnlineOrder;
  let onlineOrderTemp = null;

  let idTrans = null;

  export let items = null;

  let priceSelect = transactionType.ALL.id;
  const options = transactionTypeArr;

  // reactive
  $: groups =
    items != null
      ? items
          .filter((e, i, self) =>
            e["status"] != transactionStatus.WaitingForPayment &&
            self.indexOf(e) === i &&
            priceSelect == transactionType.OJOL.id
              ? e["ojol"] == true
              : priceSelect == transactionType.NORMAL.id
                ? e["ojol"] == null || e["ojol"] == false
                : true
          )
          .reduce((groups, item) => {
            const d = new Date(item.createdAtOnLocal);
            const date = format(d, "dd MMM yyyy");
            if (!groups[date]) {
              groups[date] = [];
            }
            groups[date].push(item);
            return groups;
          }, {})
      : [];

  $: groupMap = Object.keys(groups).map((date) => {
    return {
      date,
      items: groups[date].sort((a, b) => {
        // sort transaction date in descending order
        const dateA = new Date(a.createdAtOnLocal);
        const dateB = new Date(b.createdAtOnLocal);
        if (isNaN(dateA.getTime()) || isNaN(dateB.getTime())) {
          return 0; // Handle invalid dates
        }
        return dateB.getTime() - dateA.getTime(); // Perform the comparison
      }),
    };
  });

  $: dataFiltered = groupMap;

  // form validations
  const formVoid = Form(
    () => ({
      voidRemark: { value: voidRemark, validators: ["required"] },
    }),
    {
      initCheck: false,
      validateOnChange: false,
      stopAtFirstError: false,
      stopAtFirstFieldError: false,
    }
  );

  // methods
  const search = () => {
    const keyword = searchKeyword.trim().toLowerCase();
    const date = searchDate;
    const priceType = priceSelect;

    dispatch("search-change", {
      keyword,
      date,
      priceType,
    });
  };

  // void transaction
  const onClickVoidTransaction = async () => {
    formVoid.validate();
    const valid = $formVoid.valid;
    if (valid) {
      const date = searchDate;
      const storeId = $store.id || $store._id;
      isLoading.toggle();
      await $connection
        .call("transactions.requestToVoid", idTrans, storeId, voidRemark)
        .then((f) => {
          const keyword = searchKeyword.trim().toLowerCase();
          isLoading.toggle();
          globalThis.pushToast("Successfully Request Void.", "success");
          modalVoid.toggle();
          dispatch("search-change", { keyword, date });
          voidRemark = "";
        })
        .catch((err) => {
          globalThis.pushToast(err.reason, "error");
        });
    }
  };

  // recheck transaction when there is an issue in status/transaction/midtrans && only avail if payemnt type not COD
  const onClickRecheckTransaction = async () => {
    if (!codeRecheck) {
      globalThis.pushToast("Please Input Code Transaction", "error");
      return;
    }

    isLoading.toggle();
    await $connection
      .call("onlineOrders.recheck", codeRecheck)
      .then((res) => {
        if (res != null) {
          console.log(res);
          onlineOrderTemp = res;
          codeRecheck = "";
          modalRecheck.toggle();
          isLoading.toggle();
          modalOnlineOrder.toggle();
        } else {
          globalThis.pushToast("something wrong", "error");
        }
      })
      .catch((err) => {
        isLoading.toggle();
        globalThis.pushToast(err.reason, "error");
      });
  }
  async function submitChangePaymentTypeRequest(data) {
		isLoading.toggle();
		const store = {
			storeId: data.storeId,
			employeeId: $loggedInUserId,
		};
		await $connection
			.call("transactions.requestChangePaymentType", data.transactionId, store, {
				remarks: data.changePaymentTypeRemark,
				newPaymentType: data.newPaymentType,
				newPaymentDetail: data.newPaymentDetail,
			})
			.then(() => {
				const date = searchDate;
				const keyword = searchKeyword.trim().toLowerCase();
				globalThis.pushToast("Successfully Request Change Payment Type.", "success");
				dispatch("search-change", { keyword, date });
			})
			.catch((err) => {
				globalThis.pushToast(err.reason, "error");
			});
		isLoading.toggle();
		modalChangePaymentType.close();
	}
  // print function
  const onClickPrint = (type) => {
    if (type == printType.PRINT_CO) {
      const promos = {
        promos: tempTransaction["promos"].map((prom) => {
          return {
            _id: prom._id,
            promoName: prom.name,
            discountType: prom.items[0].discountType,
            discountValue: prom.discount,
            bonus: prom.bonus.map((bon) => {
              return {
                price: bon.price,
                name: bon.name,
                qty: bon.qty,
              };
            }),
          };
        }),
      };
      PrintyCo($store, tempTransaction["items"], tempTransaction, promos);
      return;
    }

    const promos = {
      promos: tempTransaction["promos"].map((prom) => {
        return {
          _id: prom._id,
          promoName: prom.name,
          discountType: prom.items[0].discountType,
          discountValue: prom.discount,
          used: prom.used,
          bonus: prom.bonus.map((bon) => {
            return {
              price: bon.price,
              name: bon.name,
              qty: bon.qty,
            };
          }),
        };
      }),
    };
    Printy($store, tempTransaction["items"], tempTransaction, promos);
  };
</script>
<ModalChangePaymentType
	bind:this={modalChangePaymentType}
	data={sectionData}
	on:close={() => {
		sectionData = null;
		modalChangePaymentType.close();
	}}
	on:submit={(e) => {
		submitChangePaymentTypeRequest(e.detail);
	}}
/>
<Modal
  index={20}
  title="Void Invoice"
  closed
  on:close={() => {
    idTrans = null;
    voidRemark = "";
    modalVoid.toggle();
  }}
  bind:this={modalVoid}
>
  <div class="w-full flex flex-col">
    <TextArea
      form={formVoid}
      name="voidRemark"
      label="Void Remarks"
      maxlength={200}
      bind:value={voidRemark}
      placeholder=""
    />
    <Button extClass="mt-5" on:click={onClickVoidTransaction} size="large">
      Save
    </Button>
  </div>
</Modal>

<!-- Modal Print -->
<Modal
  title="Confirm"
  bind:this={modalPrint}
  index={30}
  confirm
  withTitle={false}
  modalPrint
  confirmText="Want Print ?"
  on:printCo={() => onClickPrint(printType.PRINT_CO)}
  on:print={() => onClickPrint(printType.PRINT_RECEIPT)}
  on:cancel={() => {
    modalPrint.toggle();
  }}
/>

<!-- Modal Recheck Transaction  -->
<Modal
  title="Re-check Transaction"
  bind:this={modalRecheck}
  index={30}
  closed
  on:close={() => {
    codeRecheck = "";
    modalRecheck.toggle();
  }}
>
  <p class="rounded bg-gray-100 text-gray-700 font-medium px-3 py-1 text-sm">
    Note: Khusus untuk pemesanan dan pembayaran melalui Mobile App dan bukan
    Bayar di Tempat.
  </p>
  <div class="flex space-x-3 mt-3">
    <div class="flex-1 relative flex flex-wrap items-stretch w-3/4">
      <Input
        bind:value={codeRecheck}
        name="codeRecheck"
        label=""
        form={null}
        type="text"
        extClass="px-3 py-3 placeholder-gray-400 text-gray-700 relative bg-white rounded text-sm shadow outline-none focus:outline-none focus:shadow-outline w-full dark:bg-dark-third dark:text-white"
        placeholder="Masukkan Kode Transaksi..."
      />
    </div>
    <Button
      extClass="text-sm font-semibold"
      on:click={onClickRecheckTransaction}
      size="large"
    >
      Submit
    </Button>
  </div>
</Modal>

<!-- Modal Detail Transaksi  -->
<!-- trigger after success recheck transaction  -->
<Modal
  size="large"
  title="Detail Transaksi"
  index={20}
  bind:this={modalOnlineOrder}
  on:close={() => {
    modalOnlineOrder.toggle();
  }}
  on:cancel={() => {
    modalOnlineOrder.toggle();
  }}
  closed
>
  <div class="flex flex-col">
    {#if onlineOrderTemp != null}
      <div>
        <TransactionCard
          data={onlineOrderTemp}
          from="apps"
          hasPrinted={false}
          on:onprint={() => {
            tempTransaction = {};
            tempTransaction = onlineOrderTemp;
            tempTransaction = tempTransaction;
            modalPrint.toggle();
          }}
        />

        <div class="flex justify-end mb-2">
          <TransactionDetailCard {onlineOrderTemp} />
        </div>
      </div>
    {/if}
  </div>
</Modal>

<Loading bind:this={isLoading} />

<div class="relative p-4">
  <div
    class="flex relative items-center z-10 cursor-pointer"
    on:click={() => replace("/")}
  >
    <i class="bx bxs-chevron-left text-3xl" />
    <h5 class="ml-2 text-md font-medium text-dark-second">Home</h5>
  </div>
  <h4
    class="absolute z-0 top-5 left-0 right-0 text-center text-md font-medium text-dark-second"
  >
    History
  </h4>

  <div class="flex flex-wrap justify-center items-center my-14">
    <div class="max-w-2xl w-full flex flex-col justify-center items-center">
      <div
        class="flex items-center sm:flex-col space-y-4 md:space-x-4 md:space-y-0 w-full"
      >
        <!-- Filter Section  -->
        <FilterCard
          bind:searchText={searchKeyword}
          bind:selectedTransactionType={priceSelect}
          bind:selectedDate={searchDate}
          on:changeFilter={search}
        >
          <Button
            extClass="whitespace-nowrap px-3 py-3 text-sm font-semibold"
            on:click={() => modalRecheck.toggle()}
          >
            Re-check
          </Button>
        </FilterCard>
      </div>

      <div class="w-full mt-4">
        {#if items == null}
          <div class="relative p-4 mb-2">
            <div class="flex justify-between items-center">
              <h4 class="text-md font-medium text-gray-800">Loading...</h4>
            </div>
          </div>
        {:else if dataFiltered.length === 0}
          <div class="relative border border-gray-300 p-4 mb-2">
            <div class="flex justify-between items-center">
              <h4 class="text-md font-medium text-gray-800">
                No History Found.
              </h4>
            </div>
          </div>
        {/if}

        <!-- List Transaction  -->
        {#each dataFiltered as item}
          <div class="flex items-center justify-between mb-2">
            <h4 class="text-md text-dark-second font-medium">
              {Util.formatDate(new Date(item.date), "short")}
              {`(${item.items.length})`}
            </h4>
            <div
              class="relative ml-4 flex items-center justify-center bg-primary text-white px-4 py-1 rounded-md cursor-pointer
              {$inetStatus && $serverStatus ? 'cursor-pointer' : 'opacity-50 cursor-not-allowed'}"
              on:click={async () => {
                search();
              }}
            >
              <i class="bx bx-refresh text-xl mr-1" />
              <span>Refresh</span>
            </div>
          </div>
          {#each item.items as data}
            <HistoryCard
              {data}
              on:click={() => {
                if (sectionId == data["_id"]) {
                  sectionId = "";
                } else {
                  sectionId = data["_id"];
                }
              }}
              on:changePaymentType={() => {
                if ($inetStatus && $serverStatus) {
									sectionData = data;
									modalChangePaymentType.open();
								} else {
									globalThis.pushToast(
										"Oops! Server is currently offline. Please check your connection and try again",
										"error"
									);
								}
              }}
              on:void={() => {
                idTrans = null;
                idTrans = data["_id"];
                modalVoid.toggle();
              }}
              on:print={() => {
                tempTransaction = {};
                tempTransaction = data;
                tempTransaction = tempTransaction;
                modalPrint.toggle();
              }}
            />
            {#if sectionId === data["_id"]}
              <HistoryDetailCard {data} />
            {/if}
          {/each}
        {/each}
      </div>
    </div>
  </div>
</div>
