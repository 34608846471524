<script>
  import { createEventDispatcher } from "svelte";
  export let image;
  export let name;
  export let description;
  export let selected = false;
  export let selectable = false;
  export let out = false;
  const dispatch = createEventDispatcher();

  $: source = image ? image : "https://bit.ly/3pPY1jc";
</script>

<div
  class="relative overflow-hidden w-40 h-40 rounded-lg shadow-md cursor-pointer bg-white text-center flex flex-col items-center justify-center"
  on:click="{() => dispatch('card-click')}"
>
  <img
    class="object-cover w-20 h-20"
    src="{source}"
    alt="{name ? name : 'product-img'}"
  />
  <div class="py-3 px-2">
    <h4
      class="text-sm font-medium break-all overflow-ellipsis overflow-hidden text-center"
      >{name}</h4
    >
    <div class="text-xs mt-1">
      {#if description}
        <span>{description}</span>
      {:else}
        <span class="text-gray-300"> No description </span>
      {/if}
    </div>
  </div>
  {#if out}
    <div
      class="absolute top-2 right-2 rounded flex justify-center items-center w-7 h-7 bg-red-500 text-center"
    >
        <i class="bx bx-error-circle text-white"></i>
    </div>
  {/if}
  {#if selectable && !out}
    <div
      class="absolute top-2 right-2 rounded border w-7 h-7 bg-white bg-opacity-50"
    >
      {#if selected}
        <div class="text-center">
          <i class="bx bx-check  bx-sm text-primary"></i>
        </div>
      {/if}
    </div>
  {/if}
</div>
