<script>
  import { transactionType, transactionTypeArr } from "../../enums/transaction";
  import Button from "../control/Button.svelte";
  import Input from "../control/Input.svelte";
  import Select from "../control/Select.svelte";
  import Util, { blockedDate } from "../../common/Util/Utility";
  import { createEventDispatcher } from "svelte";
  const dispatch = createEventDispatcher();

  export let searchText = "";
  export let selectedTransactionType = transactionType.ALL.id;
  export let selectedDate = format(new Date(), "yyyy-MM-dd");

  let options = transactionTypeArr;

  const search = () => {
    dispatch("changeFilter", {
      searchText,
      selectedTransactionType,
      selectedDate,
    });
  };
</script>

<form class="flex flex-row w-full items-center space-x-2">
  <div class="relative flex flex-wrap items-stretch w-3/4">
    <Input
      bind:value={searchText}
      name="searchText"
      leftIcon="search"
      label=""
      form={null}
      type="text"
      extClass="px-3 py-3 placeholder-gray-400 text-gray-700 relative bg-white rounded text-sm shadow outline-none focus:outline-none focus:shadow-outline w-full pl-10 dark:bg-dark-third dark:text-white"
      placeholder="Search here..."
      removeMarginTopField={true}
      on:changeInput={search}
    />
  </div>
  <div class="relative flex flex-wrap items-stretch w-1/4">
    <Select
      bind:selected={selectedTransactionType}
      name="selectedTransactionType"
      {options}
      extClass="flex-1 appearance-none cursor-pointer block text-sm text-dark-second dark:text-white dark:bg-dark-third shadow outline-none rounded p-3 ring-transparent focus:ring-transparent
      focus:border-gray-800 pr-7"
      on:change={search}
    />
  </div>
  <div class="relative flex flex-wrap items-stretch w-1/4">
    <Input
      bind:value={selectedDate}
      name="selectedDate"
      min={blockedDate}
      label=""
      form={null}
      type="date"
      extClass="px-3 py-3 placeholder-gray-400 text-gray-700 relative bg-white rounded text-sm shadow outline-none focus:outline-none focus:shadow-outline w-full dark:bg-dark-third dark:text-white"
      removeMarginTopField={true}
      on:changeInput={search}
    />
  </div>
  <slot />
</form>
