<script lang="ts">
  import { replace } from "svelte-spa-router";
  import InputCheck from "../common/control/InputCheck.svelte";
  import TopNotif from "../common/TopNotif.svelte";
  import Utility from "../common/Util/Utility";

  let items = JSON.parse(localStorage.getItem("promos") || "[]");

  let active = 0;
</script>

<div class="relative p-4">
  <div
    class="flex relative items-center z-10 cursor-pointer"
    on:click={() => replace("/")}
  >
    <i class="bx bxs-chevron-left text-3xl" />
    <h5 class="ml-2 text-md font-medium text-dark-second">Home</h5>
  </div>
  <h4
    class="absolute z-0 top-5 left-0 right-0 text-center text-md font-medium text-dark-second"
  >
    Promo
  </h4>

  {#if items.length < 1}
    <div class="relative border border-gray-300 p-4 mb-2 mt-6">
      <div class="flex justify-between items-center">
        <h4 class="text-md font-medium text-gray-800">No Promo Found.</h4>
      </div>
    </div>
  {/if}
  <div class="flex flex-col md:flex-row mt-6">
    <div class="w-full md:w-3/12">
      {#each items as item, i}
        <div
          class="border border-gray-200 p-4 cursor-pointer {active == i
            ? 'bg-gray-200 hover:bg-gray-100'
            : 'bg-white hover:bg-gray-100'}"
          on:click={() => {
            active = i;
          }}
        >
          <h4 class="font-medium text-2xl">{item["name"]}</h4>
          <p class="text-sm">{item["description"]}</p>
        </div>
      {/each}
    </div>
    <div class="w-full md:w-9/12">
      {#each items as item, i}
        {#if active == i}
          <div class="px-4 py-4 md:px-10 md:py-0">
            <h4 class="font-medium text-2xl">{item["name"]}</h4>
            <p class="text-sm text-gray-500">
              {Utility.formatDate(new Date(item["period"]["from"]), "short")} -
              {Utility.formatDate(new Date(item["period"]["to"]), "short")}
            </p>
            <div class="my-4" />
            <p class="mb-3 text-gray-700">
              {item["description"]}
            </p>
            <div class="flex items-center">
              <div class="flex-1 flex flex-col">
                <p class="font-medium mb-2">Items:</p>
                <div class="grid grid-cols-4 gap-4">
                  {#each item["items"] as promos}
                    <div class="bg white shadow-lg border rounded-md p-4">
                      <p class="m-0 text-sm font-medium">
                        Discount Type: {promos["discountType"]}
                      </p>
                      {#if promos["discountType"] != "BONUS"}
                        <p class="m-0 text-sm font-medium">
                          Value:
                          {promos["discountType"] == "PERCENT"
                            ? promos["value"] + "%"
                            : Utility.formatRupiah(promos["value"], "Rp. ")}
                        </p>
                      {:else}
                        <p class="m-0 text-sm font-medium">Bonus:</p>
                        {#each promos["bonus"] as product}
                          <InputCheck
                            text={`${product["qty"]} ${product["name"]}`}
                            checked
                            disabled
                          />
                        {/each}
                      {/if}
                      <div class="flex-1 flex flex-col space-y-2 mt-2">
                        <p class="m-0 text-sm font-medium">Products:</p>
                        {#each promos["products"] as product}
                          <InputCheck
                            text={`${product["qty"]} ${product["name"]}`}
                            checked
                            disabled
                          />
                        {/each}
                      </div>
                    </div>
                  {/each}
                </div>
              </div>
            </div>
          </div>
        {/if}
      {/each}
    </div>
  </div>
  <TopNotif />
</div>
