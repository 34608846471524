<script lang="ts">
  import { onMount } from "svelte";
  import Toast from "../common/Toast.svelte";
  import MenuTemplate from "../templates/MenuTemplate.svelte";
  import { store, user } from "../stores/store";
  import { replace } from "svelte-spa-router";

  onMount(() => {
    if ($store == null) {
      replace("/");
    }
  });
</script>

<Toast />
{#if $store != null && $store != undefined && $user != null}
  <MenuTemplate />
{/if}
