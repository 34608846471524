<script lang="ts">
	import { replace } from "svelte-spa-router";
	import Layout from "../common/Layout.svelte";
	import { connection } from "../stores/store";
	import SyncTemplate from "../templates/SyncTemplate.svelte";

	$: if ($connection.userId == undefined) {
		replace("/");
	}
</script>

{#if $connection.userId != undefined}
	<Layout>
		<SyncTemplate />
	</Layout>
{/if}
