import { loggedInUserId } from "./stores/store";
import { wrap } from "svelte-spa-router/wrap";
import Home from "./pages/Home.svelte";
import Mark from "./pages/Mark.svelte";
import Login from "./pages/Login.svelte";
import Account from "./pages/Account.svelte";
import Pos from "./pages/Pos.svelte";
import History from "./pages/History.svelte";
import Promo from "./pages/Promo.svelte";
import NotFound from "./pages/NotFound.svelte";
import Register from "./pages/Register.svelte";
import Error from "./pages/Error.svelte";
import Sync from "./pages/Sync.svelte";
import Print from "./pages/Print.svelte";
import PrintReceipt from "./pages/PrintReceipt.svelte";
import PrintReport from "./pages/PrintReport.svelte";
import ForgotPassword from "./pages/ForgotPassword.svelte";
import Menu from "./pages/Menu.svelte";
let userId;
loggedInUserId.subscribe((val) => {
    userId = val;
});
const routes = {
    "/": wrap({
        component: Home,
        userData: {
            userId,
        },
        conditions: [
            (e) => {
                return userId && localStorage.getItem("mark") ? true : false;
            },
        ],
    }),
    "/mark": wrap({
        component: Mark,
        userData: {
            userId,
        },
        conditions: [
            (e) => {
                return userId ? true : false;
            },
        ],
    }),
    "/unauthorized": Error,
    "/unauthorized/:_message": Error,
    "/login": Login,
    "/register": Register,
    "/account": Account,
    "/pos": Pos,
    "/promo": Promo,
    "/history": History,
    "/menu": Menu,
    "/sync-data": Sync,
    "/forgot-password": ForgotPassword,
    "/reset-password": ForgotPassword,
    "/print/*": Print,
    "/receipt/print": PrintReceipt,
    "/report/print": PrintReport,
    "/report/shiftprint": PrintReport,
    // The catch-all route must always be last
    "*": NotFound,
};
export default routes;
