<script lang="ts">
	import ForgotPasswordTemplate from '../templates/ForgotPasswordTemplate.svelte';
	import Layout from '../common/Layout.svelte';
	import { form as Form } from 'svelte-forms';
	import Toast from '../common/Toast.svelte';
	import Loading from '../common/Loading.svelte';
	import { objOfflineStatus, user, store, connection } from '../stores/store';
	import { location } from 'svelte-spa-router';

	let _data = {
		email: '',
		password: '',
		repeatPassword: '',
	};

	let isLoading;

	const formForgot = Form(
		() => ({
			email: { value: _data.email, validators: ['required'] },
		}),
		{
			initCheck: false,
			validateOnChange: false,
			stopAtFirstError: false,
			stopAtFirstFieldError: false,
		},
	);

	const formReset = Form(
		() => ({
			password: { value: _data.password, validators: ['required'] },
			repeatPassword: {
				value: _data.repeatPassword,
				validators: ['required', 'equal:' + _data.password],
			},
		}),
		{
			initCheck: false,
			validateOnChange: false,
			stopAtFirstError: false,
			stopAtFirstFieldError: false,
		},
	);

	function handleSubmit(e) {
		const { data } = e.detail;
		_data = data;

		if ($location == '/forgot-password') {
			formForgot.validate();
			const valid = $formForgot.valid;
			if (valid) {
				if ($objOfflineStatus.status == 'online') {
					isLoading.toggle();
					$connection
						.call('users.password.reset.byEmail', data.email)
						.then((f) => {
							globalThis.pushToast('Successfully Reset Password.', 'success');
							_data = {
								email: '',
								password: '',
								repeatPassword: '',
							};
						})
						.catch((err) => {
							console.log(err);
							globalThis.pushToast(err.reason, 'error');
						});
					isLoading.toggle();
				}
			}
		} else if ($location == '/reset-password') {
			formReset.validate();
			const valid = $formReset.valid;
			if (valid) {
				if ($objOfflineStatus.status == 'online') {
				}
			}
		}
	}
</script>

<Loading text="Loading..." bind:this="{isLoading}" />
<Layout>
	<ForgotPasswordTemplate
		data="{_data}"
		location="{$location}"
		form="{$location == '/forgot-password' ? formForgot : formReset}"
		on:submit="{(e) => handleSubmit(e)}"
	/>
</Layout>
