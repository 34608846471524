<script>
	import { createEventDispatcher } from "svelte";
	import TransactionCard from "../card/TransactionCard.svelte";
	import Modal from "../Modal.svelte";
	import Util from "../Util/Utility";

	const dispatch = createEventDispatcher();

	export let onlineOrderTemp;
	let modalOnlineOrder;

	export function open() {
		modalOnlineOrder.toggle();
	}

	export function close() {
		modalOnlineOrder.close();
	}
</script>

<Modal
	size="large"
	title="Detail Transaksi"
	index={20}
	bind:this={modalOnlineOrder}
	on:close={() => {
		modalOnlineOrder.close();
	}}
	on:cancel={() => {
		modalOnlineOrder.close();
	}}
	closed
>
	<div class="flex flex-col">
		{#if onlineOrderTemp != null}
			<div>
				<TransactionCard
					data={onlineOrderTemp}
					from="apps"
					on:onprint={() => {
						dispatch("print");
						// todo : dispatch below code if this modal is going to be used
						// tempDataForPrint = {};
						// tempDataForPrint = onlineOrderTemp;
						// tempDataForPrint = tempDataForPrint;
						// modalPrint.toggle();
					}}
				/>

				<div class="flex justify-end mb-2">
					<div class="border border-gray-200 bg-white rounded-md shadow-md w-2/3 flex flex-col p-4 space-y-2">
						{#each onlineOrderTemp["items"] as product}
							<div class="flex justify-between items-center">
								<h4 class="text-md text-gray-800 font-medium">
									({product["amount"]})
									<span class="text-primary">{product["name"]}</span>
								</h4>
								<p class="text-md text-gray-800 font-medium">
									{Util.formatRupiah(product["price"], "Rp. ")}
								</p>
							</div>
							{#each product["additionals"] as additionals}
								{#each additionals["items"] as additional}
									<div class="border-l-2 border-green-500 flex items-center justify-between pl-2 ml-2">
										<h4 class="text-sm text-gray-800 font-medium">
											{additional["nameParent"] ? additional["nameParent"] : ""}
											-
											{additional["name"]}
										</h4>
										<p class="text-md text-green-500">
											+ {Util.formatRupiah(additional["price"], "Rp. ")}
										</p>
									</div>
								{/each}
							{/each}
							{#if product["toppings"].length > 0}
								{#each product["toppings"] as topping}
									<div class="border-l-2 border-green-500 flex items-center justify-between pl-2 ml-2">
										<h4 class="text-sm text-gray-800 font-medium">
											{topping["name"]}
										</h4>
										<p class="text-md text-green-500">
											+ {Util.formatRupiah(topping["price"], "Rp. ")}
										</p>
									</div>
								{/each}
							{/if}
						{/each}
						<hr class="my-3" />
						<div class="flex justify-between items-center">
							<h4 class="text-sm text-gray-800 font-medium">Subtotal</h4>
							<p class="text-md text-gray-800 font-medium">
								{Util.formatRupiah(onlineOrderTemp["subtotal"], "Rp. ")}
							</p>
						</div>
						<div class="flex justify-between">
							<h4 class="text-sm text-gray-800 font-medium">Promo Discount</h4>
							<div class="flex flex-col items-end">
								{#if onlineOrderTemp["promos"].length == 0}
									<p class="text-md text-red-500 font-medium">
										- {Util.formatRupiah(onlineOrderTemp["totalDiscount"], "Rp.")}
									</p>
								{/if}
							</div>
						</div>
						{#each onlineOrderTemp["promos"] as promo}
							{#each promo["used"] as promoUsed}
								<div class="flex justify-between">
									<h4 class="text-sm text-gray-800 font-medium ml-3 pl-3 border-l-2 border-red-500">
										{promo["name"]}
									</h4>
									<div class="flex flex-col items-end">
										{#if promoUsed["bonus"].length == 0}
											{#if promoUsed["discount"] != 0}
												<p class="text-md text-red-500 font-medium">
													- {Util.formatRupiah(parseInt(promoUsed["discount"]), "Rp.")}
												</p>
											{/if}
										{/if}
										{#if promoUsed["bonus"].length > 0}
											<div class="flex flex-col space-y-2 items-end">
												{#each promoUsed["bonus"] as bonus}
													<p class="text-md text-green-500 font-medium">
														+ Free {bonus["qty"]}
														{bonus["name"]}
													</p>
												{/each}
											</div>
										{/if}
									</div>
								</div>
							{/each}
						{/each}
						<div class="flex justify-between">
							<h4 class="text-sm text-gray-800 font-medium">Store Discount</h4>
							<div class="flex flex-col items-end">
								<p class="text-md text-red-500 font-medium">
									- {onlineOrderTemp["totalStoreDiscount"]
										? Util.formatRupiah(parseInt(onlineOrderTemp["totalStoreDiscount"]), "Rp.")
										: "Rp. 0"}
								</p>
							</div>
						</div>
						<div class="flex justify-between items-center">
							<h4 class="text-sm text-gray-800 font-medium">Service Charge</h4>
							<p class="text-md text-gray-800 font-medium">
								{Util.formatRupiah(parseInt(onlineOrderTemp["serviceCharge"]), "Rp. ")}
							</p>
						</div>
						<div class="flex justify-between items-center">
							<h4 class="text-sm text-gray-800 font-medium">Tax</h4>
							<p class="text-md text-gray-800 font-medium">
								{Util.formatRupiah(parseInt(onlineOrderTemp["tax"]), "Rp. ")}
							</p>
						</div>
						<div class="flex justify-between items-center">
							<h4 class="text-sm text-gray-800 font-medium">Discount</h4>
							<p class="text-md text-red-500 font-medium">
								- {Util.formatRupiah(parseInt(onlineOrderTemp["fixDiscount"] || 0), "Rp. ")}
							</p>
						</div>
						<div class="flex justify-between items-center">
							<h4 class="text-sm text-gray-800 font-medium">Rounding</h4>
							<p class="text-md {onlineOrderTemp['roundingType'] == 'UP' ? 'text-gray-800' : 'text-red-500'} font-medium">
								{onlineOrderTemp["roundingType"] == "UP"
									? Util.formatRupiah(parseInt(onlineOrderTemp["rounding"]) || 0, "Rp. ")
									: "- " + Util.formatRupiah(parseInt(onlineOrderTemp["rounding"]) || 0, "Rp. ")}
							</p>
						</div>
						<hr class="my-3" />
						<div class="flex justify-between items-center mb-2">
							<h4 class="text-md text-gray-800 font-medium">Total</h4>
							<p class="text-md text-gray-800 font-medium">
								{Util.formatRupiah(onlineOrderTemp["total"], "Rp. ")}
							</p>
						</div>
						<div class="flex justify-between items-center">
							<h4 class="text-md text-gray-800 font-medium">
								{onlineOrderTemp["paymentType"].toUpperCase()}
							</h4>
							<p class="text-md text-gray-800 font-medium">
								{Util.formatRupiah(onlineOrderTemp["paymentDetail"]["paymentValue"], "Rp. ")}
							</p>
						</div>
						<div class="flex justify-between items-center">
							<h4 class="text-md text-gray-800 font-medium">Kembalian</h4>
							<p class="text-md text-gray-800 font-medium">
								{Util.formatRupiah(onlineOrderTemp["paymentDetail"]["paymentValue"] - onlineOrderTemp["total"], "Rp. ")}
							</p>
						</div>
						{#if onlineOrderTemp["requestToVoid"]}
							<hr class="my-3" />
							<div class="flex flex-col">
								<h4 class="text-md text-gray-800 font-medium">Void Remarks</h4>
								<p class="text-md text-gray-800">
									{onlineOrderTemp["remarks"]}
								</p>
							</div>
						{/if}
					</div>
				</div>
			</div>
		{/if}
	</div>
</Modal>
