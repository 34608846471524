<script>
  import { createEventDispatcher } from "svelte";
  const dispatch = createEventDispatcher();

  export let status = "primary";
  export let disabled = false;
  export let text = "";
  export let textRight = "";
  export let value = false;
  export let name = "";
  export let size = "medium";
  export let checked = false;
  export let notText = false;
  export let notLabel = false;
  let classes = "";
  let classesDot = "";

  if (size === "small") {
    classes = "w-6 h-3";
    classesDot = "w-3 h-3 right-3 s";
  } else if (size === "medium") {
    classes = "w-10 h-5";
    classesDot = "w-5 h-5 right-5 m";
  } else {
    classes = "w-14 h-7";
    classesDot = "w-7 h-7 right-7 l";
  }
</script>

<div class="inline-block">
  {#if !notLabel}
    <p class=" flex flex-wrap text-primary text-sm font-bold mb-2">{text}</p>
  {/if}
  <div
    class="flex items-center {disabled ? 'cursor-not-allowed' : 'cursor-pointer'}"
    on:click={() => {
      dispatch("change", { value, checked });
    }}
  >
    <div
      class="w-10 h-4 flex items-center bg-gray-300 rounded-full p-0 duration-300 shadow-md ease-in-out {checked
        ? 'bg-green-400'
        : ''} {disabled ? 'opacity-50' : ''}"
    >
      <div
        class="bg-white w-5 h-5 rounded-full shadow-md transform duration-300 ease-in-out {checked
          ? 'translate-x-5'
          : ''}"
      />
    </div>
    {#if !notText}
      <p
        class="block {checked
          ? 'text-green-500'
          : 'text-gray-600'} font-bold text-sm italic ml-2 {disabled ? 'opacity-50' : ''}"
      >
        {textRight}
      </p>
    {/if}
  </div>
</div>
