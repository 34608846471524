<script>
  import { createEventDispatcher } from "svelte";
  import { excludedPaymentType, paymentTypeStatus } from "../enums/paymentType";
  import {
    paymentTypes,
    store,
    serverStatus,
    inetStatus,
    connection,
  } from "../stores/store";
  import Switch from "./control/Switch.svelte";
  import Util from "./Util/Utility";
  import LoadingSpinner from "./LoadingSpinner.svelte";
  import LoadingSkeletonBar from "./LoadingSkeletonBar.svelte";
	import Utility from "./Util/Utility";

  const dispatch = createEventDispatcher();
  let isExpand = false;
  let isLoading = false;
  const title = "Payment Type Configuration";

  let mappedPaymentTypes = [];
  let storeData = null;

  const onClickItem = async () => {
    let isOpen = !isExpand == true;
    const storeId = $store.id || $store._id;

    // if open
    if (isOpen && storeId) {
      isLoading = true;
      console.log($serverStatus && $inetStatus ? "IS ONLINE" : "IS OFFLINE");
      // check is online load payment type
      if ($serverStatus && $inetStatus) {
        await loadPaymentTypesAndStore(storeId);

        isLoading = false;
        isExpand = !isExpand;
      } else {
        // is offline get prev data from $paymentTypes
        isLoading = false;
        if ($paymentTypes?.length && storeId) {
          storeData = $store;
          mappedPaymentTypes = mappingPaymentTypes($paymentTypes, storeData);
        } else {
          globalThis.pushToast(
            "Oops! Server is currently offline. Please check your connection and try again",
            "error"
          );
        }

        isExpand = !isExpand;
      }
    } else {
      isExpand = !isExpand;
    }
  };

  const loadPaymentTypesAndStore = async (_storeId) => {
    const [resPaymentTypes, resStore] = await Promise.all([
      $connection.call("paymentTypes.forList"),
      $connection.call("stores.byId", _storeId),
    ]);
    storeData = resStore;
    let sortedPaymentType = Utility.sortPaymentTypeByCreatedAt(resPaymentTypes);
    mappedPaymentTypes = mappingPaymentTypes(sortedPaymentType, storeData);
    paymentTypes.set(sortedPaymentType);
  };

  const mappingPaymentTypes = (_paymentTypes = [], _store) => {
    if (!_paymentTypes || !_store) return [];

    return _paymentTypes
      .filter(
        (e) =>
          !excludedPaymentType.includes(e.code) &&
          e.status == paymentTypeStatus.Active
      )
      .map((payType) => ({
        code: payType.code,
        name: payType.name,
        activeInPos: Util.resolveActiveInPosValue(payType, _store),
      }));
  };

  const updatePaymentTypeActiveInPos = async (e) => {
    isLoading = true;
    const { storeId, paymentTypeCode, status } = e;

    try {
      await $connection.call(
        "stores.paymentType.update.activeInPos",
        storeId,
        paymentTypeCode,
        status
      );

      await loadPaymentTypesAndStore(storeId);

      globalThis.pushToast("Successfully Changed Payment Method.", "success");

      isLoading = false;
    } catch (err) {
      globalThis.pushToast("Something Wrong!", "error");
      console.log(err);
      isLoading = false;
    }
  };
</script>

<div class="w-full place-items-center">
  <div class="w-full transition">
    <div
      class={` bg-primary cursor-pointer border-b relative transition p-3 rounded-md `}
      on:click={() => onClickItem()}
    >
      <div class="flex justify-between items-center text-white">
        <h3 class="font-medium">
          {title}
        </h3>
        <i class={`bx bx-chevron-${isExpand ? "down" : "up"}`} />
      </div>
    </div>
    {#if !isLoading}
      {#if isExpand && mappedPaymentTypes?.length}
        <div
          id="scrollbarSide"
          class="accordion-content-pos pt-0 max-h-sm overflow-y-auto border-2 border-black px-2"
        >
          {#each mappedPaymentTypes as paymentType}
            <div class="flex justify-between">
              <p class="w-3/4 break-words-self">{paymentType.name}</p>
              <div class="flex items-center justify-center w-1/4">
                <Switch
                  value={paymentType.activeInPos}
                  checked={paymentType.activeInPos}
                  notLabel
                  textRight={paymentType.activeInPos
                    ? paymentTypeStatus.Active
                    : paymentTypeStatus.InActive}
                  on:change={async () => {
                    console.log(
                      $serverStatus && $inetStatus ? "IS ONLINE" : "IS OFFLINE"
                    );
                    // check is online
                    if ($serverStatus && $inetStatus) {
                      await updatePaymentTypeActiveInPos({
                        storeId: $store.id || $store._id,
                        paymentTypeCode: paymentType.code,
                        status: !Util.resolveActiveInPosValue(
                          paymentType,
                          storeData
                        ),
                      });
                    } else {
                      globalThis.pushToast(
                        "Oops! Server is currently offline. Please check your connection and try again",
                        "error"
                      );
                    }
                  }}
                />
              </div>
            </div>
            <hr class="border-gray-200" />
          {/each}
        </div>
      {:else}
        <div class="h-60 max-h-sm"></div>
      {/if}
    {:else}
      <LoadingSkeletonBar nBar="4" />
    {/if}
  </div>
</div>

<style>
  .accordion-content {
    transition:
      max-height 0.3s ease-out,
      padding 0.3s ease;
  }
</style>
