<script>
  import Util from "../Util/Utility";
  import { createEventDispatcher } from "svelte";
  const dispatch = createEventDispatcher();

  export let data;
</script>

<div class="relative mb-2">
  <div class="flex">
    <div
      class="flex-1 p-4 relative border border-gray-200 bg-white shadow-md rounded-tl-lg rounded-bl-lg cursor-pointer hover:bg-gray-100"
      on:click={() => {
        dispatch("click");
      }}
    >
      {#if data["error"]}
        <div
          class="px-4 py-1 flex items-center rounded-lg bg-red-500 text-white mb-2 font-medium"
        >
          <i class="bx bx-error-circle mr-1" />
          {data["error"]}
        </div>
      {/if}
      <div class="flex justify-between items-center">
        <h4 class="text-md font-medium text-gray-800">
          Transaction #{data["data"]["code"].substring(0, 8).toUpperCase()}
          {#if data["data"]["ojol"]}
            <span class="bg-green-500 rounded-md p-2 text-white text-sm">
              OJOL
            </span>
          {/if}
        </h4>
        <span class="text-sm text-gray-500"
          >{Util.formatDate(
            new Date(data["data"]["createdAtOnLocal"]),
            "long-detail"
          )}</span
        >
      </div>
      <div class="flex justify-between items-center">
        <h4 class="text-sm text-gray-800">
          Order No: <span class="font-medium"
            >{data["data"]["orderNo"] ? data["data"]["orderNo"] : "-"}</span
          >
        </h4>
      </div>
      <div class="flex justify-between items-center">
        <h4 class="text-sm text-gray-800">
          Status: <span
            class="font-medium {data['status']
              ? data['status'] != 'SUCCESS' &&
                data['status'] != 'WAIT_FOR_SYNC' &&
                data['status'] != 'SYNCING'
                ? 'text-red-500'
                : 'text-green-500'
              : 'text-green-500'}"
            >{data["status"]
              ? data["status"] == "WAIT_FOR_SYNC"
                ? "WAIT FOR SYNC"
                : data["status"]
              : "SUCCESS"}</span
          >
        </h4>
      </div>
      <div class="flex justify-between items-center">
        <h4 class="text-sm text-gray-800">
          Items ({data["data"]["items"].reduce(
            (sum, item) => sum + item.amount,
            0
          )})
        </h4>
      </div>
      <div class="flex space-x-2 items-center">
        <i class="bx bx-user" />
        <h4 class="text-sm  text-gray-800">
          {data["customer"]["name"] ? data["customer"]["name"] : "-"}
        </h4>
      </div>
      <div class="flex space-x-2 items-center">
        <i class="bx bx-phone" />
        <h4 class="text-sm  text-gray-800">
          {data["customer"]["phone"] ? data["customer"]["phone"] : "-"}
        </h4>
      </div>
      <div class="flex space-x-2 items-center">
        <i class="bx bx-chair" />
        <h4 class="text-sm  text-gray-800">
          {data["customer"]["table"] ? data["customer"]["table"] : "-"}
        </h4>
      </div>
      <p
        class="absolute right-4 bottom-4 text-lg text-gray-800 font-medium m-0"
      >
        {Util.formatRupiah(data["data"]["total"], "Rp. ")}
      </p>
    </div>
    <div
      class="bg-primary rounded-tr-lg rounded-br-lg flex items-center justify-center px-4 hover:opacity-90 cursor-pointer"
      on:click={() => {
        dispatch("print");
      }}
    >
      <i class="bx bx-printer text-2xl text-white" />
    </div>
  </div>
</div>
