<script lang="ts">
	import PrintTemplate from '../templates/PrintTemplate.svelte';
	import printJS from 'print-js';
	import { onMount } from 'svelte';

	export let params = {};
	//TODO: change to pouchdb
	let data;
	let orders = JSON.parse(localStorage.getItem('orders') || '[]');
	let tempOrders = JSON.parse(localStorage.getItem('temporders') || '[]');

	$: if (params) {
		const loadData =
			orders.filter((ord) => ord['id'] == params['wild']).length > 0
				? orders.filter((ord) => ord['id'] == params['wild'])
				: tempOrders.filter((ord) => ord['data']['id'] == params['wild'])
						.length > 0
				? tempOrders.filter((ord) => ord['data']['id'] == params['wild'])
				: null;

		if (
			tempOrders.filter((ord) => ord['data']['id'] == params['wild']).length > 0
		) {
			data = [loadData[0]['data']];
		} else {
			data = loadData;
		}
	}

	onMount(() => {
		// printHTML();
		// window.print();
		// window.close();
	});

	// function printHTML() {
	// 	printJS({
	// 		printable: 'printablex',
	// 		type: 'html',
	// 		targetStyles: ['*'],
	// 	});
	// }
</script>

<div id="printablex">
	<PrintTemplate dataFrom="{data}" />
</div>
