<script>
  import Util from "../common/Util/Utility";
  export let dataFrom;

  $: data = dataFrom[0];
  $: console.log(dataFrom);
</script>

<div id="screen" class="bg-gray-300 p-7 min-h-screen relative">
  <div
    id="container"
    class="container bg-white p-2 w-96 mx-auto border border-gray-600 flex flex-col space-y-10"
  >
    {#each [1, 2] as i}
      <div
        style="font-family: Lucida Console; font-size: 11pt; -webkit-print-color-adjust: exact;"
        class={i == 1 ? "hide-on-screen" : ""}
      >
        <div
          style="text-align:center;white-space:nowrap;overflow:hidden;margin-bottom:10pt;"
        >
          <p
            style="font-size:10pt; white-space:nowrap;overflow:hidden;margin:0;"
          >
            <span style="font-size:13pt; font-weight:bold;">
              {data["store"]["name"]}
            </span>
            <br />
            {data["store"]["address"]}
            <br />
            Telp: +62 {data["store"]["phone"]["number"]}
          </p>
        </div>
        <div style="width:100%; font-size:11pt; color: #000;">
          <div
            style="display:flex; justify-content: space-between; margin:5pt 0;"
          >
            <span style="flex:1;"
              >Customer: {data["customer"]["name"]
                ? data["customer"]["name"]
                : "-"}</span
            >
          </div>
          <div style="display:flex; justify-content: space-between;">
            <span style="flex:1;">ID. {data["code"].toUpperCase()}</span>
            <span style="flex:1; text-align:right;"
              >{Util.formatDate(new Date(data["createdAt"]), "detail")}</span
            >
          </div>
          <hr
            style="margin-top:3pt; background-color:#000; border-color:#000; color: #000;"
          />
          {#each data["items"] as item, i}
            <div
              style="display:flex; flex-direction:row; align-items:center; margin-top:3pt;"
            >
              <div style="margin-right:10pt;">
                {i + 1}.
              </div>
              <div style="flex:1;">
                {item["name"]}
              </div>
              <div style="flex:1; text-align:center;">
                ({item["amount"]})
              </div>
              <div style="flex:1; text-align:right;">
                {Util.formatRupiah(item["price"], "Rp. ")}
              </div>
            </div>
            {#each item["additionals"] as adds}
              {#each adds["items"] as add}
                <div style="display:flex; flex-direction:row; margin-top:3pt;">
                  <div style="flex:2; text-align:right">
                    {add["nameParent"] ? add["nameParent"] : ""} - {add["name"]}
                  </div>
                  <div style="flex:1; text-align:right;">
                    {Util.formatRupiah(add["price"], "Rp. ")}
                  </div>
                </div>
              {/each}
            {/each}
            {#each item["toppings"] as topping}
              <div style="display:flex; flex-direction:row; margin-top:3pt;">
                <div style="flex:2; text-align:right">
                  {topping["name"]}
                </div>
                <div style="flex:1; text-align:right;">
                  {Util.formatRupiah(topping["price"], "Rp. ")}
                </div>
              </div>
            {/each}
          {/each}
          <hr
            style="margin-top:3pt; margin-bottom:3pt; background-color:#000; border-color:#000; color: #000;"
          />
          <div style="display:flex; flex-direction:row; margin-bottom:3pt;">
            <div style="flex:1">SubTotal</div>
            <div style="flex:1 text-align:right">
              {Util.formatRupiah(data["subtotal"], "Rp. ")}
            </div>
          </div>
          <div
            style="display:flex; flex-direction:row; justify-content: space-between"
          >
            <div style="flex:1">Discount</div>
            {#if data["promos"].length == 0}
              <div style="flex:1; text-align:right;">- Rp. 0</div>
            {/if}
          </div>
          {#each data["promos"] as promo}
            <div style="display:flex; flex-direction:row;">
              <div style="flex:1; text-align:right; margin-top:5pt;">
                {promo["name"]}
              </div>
              <div
                style="flex:1; display:flex; flex-direction:column; text-align:right;"
              >
                {#if promo["discount"] > 0}
                  <div style="flex:1; text-align:right; margin-top:5pt;">
                    ({Util.formatRupiah(promo["discount"], "Rp. ")})
                  </div>
                {/if}
                {#if promo["bonus"].length > 0}
                  {#each promo["bonus"] as bonus}
                    <div style="flex:1; text-align:right; margin-top:5pt;">
                      + Free {bonus["qty"]}
                      {bonus["name"]}
                    </div>
                  {/each}
                {/if}
              </div>
            </div>
          {/each}
          <div style="display:flex; flex-direction:row; margin-bottom:3pt;">
            <div style="flex:1">Tax</div>
            <div style="flex:1 text-align:right">
              {Util.formatRupiah(parseInt(data["tax"]) || 0, "Rp. ")}
            </div>
          </div>
          <div style="display:flex; flex-direction:row; margin-bottom:3pt;">
            <div style="flex:1">Service Charge</div>
            <div style="flex:1 text-align:right">
              {Util.formatRupiah(data["serviceCharge"] || 0, "Rp. ")}
            </div>
          </div>
          <div style="display:flex; flex-direction:row; margin-bottom:3pt;">
            <div style="flex:1">Rounding</div>
            <div style="flex:1 text-align:right">
              {data["roundingType"] == "UP"
                ? Util.formatRupiah(parseInt(data["rounding"]) || 0, "Rp. ")
                : "(" +
                  Util.formatRupiah(parseInt(data["rounding"]) || 0, "Rp. ") +
                  ")"}
            </div>
          </div>
          <hr
            style="margin-top:3pt; margin-bottom:3pt; background-color:#000; border-color:#000; color: #000;"
          />
          <div style="display:flex; flex-direction:row; margin-bottom:3pt;">
            <div style="flex:1">Total</div>
            <div style="flex:1 text-align:right; font-weight:bold;">
              {Util.formatRupiah(data["total"], "Rp. ")}
            </div>
          </div>
          <div
            style="display:flex; flex-direction:row; margin-top:3pt; margin-bottom:3pt;"
          >
            <div style="flex:1">
              {data["paymentType"].toUpperCase()}
            </div>
            <div style="flex:1 text-align:right; font-weight:bold;">
              {Util.formatRupiah(data["paymentDetail"]["paymentValue"], "Rp. ")}
            </div>
          </div>
          <div
            style="display:flex; flex-direction:row; margin-top:3pt; margin-bottom:10pt;"
          >
            <div style="flex:1">Kembalian</div>
            <div style="flex:1 text-align:right;">
              {Util.formatRupiah(
                data["paymentDetail"]["paymentValue"] - data["total"],
                "Rp. "
              )}
            </div>
          </div>
          <div style="display:flex; flex-direction:row;">
            <div style="flex:1">Campaign Contribution:</div>
            {#if !data["campaigns"] || data["campaigns"].length == 0}
              <div style="flex:1 text-align:right;">-</div>
            {/if}
          </div>
          {#if data["campaigns"]}
            {#each data["campaigns"] as campaign}
              <div style="display:flex; flex-direction:row; margin-top:3pt;">
                <div style="flex:1; text-align:right">{campaign["name"]}</div>
                <div style="flex:1; text-align:right;">
                  {Util.formatRupiah(campaign["contribution"], "Rp. ")}
                </div>
              </div>
            {/each}
          {/if}
          <!-- <div style="margin-bottom:3pt; margin-top:3pt;">Notes: -</div> -->
          <!-- // <div style="margin-left:3pt; margin-bottom:2pt;">- Kamu mendapatkan sovenir cantik untuk pembelian berikutnya.</div>
			// <div style="margin-left:3pt;">- Point kamu 1080.</div> -->
        </div>
        <footer style="margin-top:10pt; text-align:center; font-size:11pt;">
          Terima kasih telah berbelanja di Toko kami.
          <br /><br />Bajawa &copy 2021
        </footer>
      </div>
      {#if i == 1}
        <hr class="hide-on-screen" style="border-top: 1px dashed black;" />
      {/if}
    {/each}
  </div>
</div>

<style>
  @media screen {
    .hide-on-screen {
      display: none;
    }
  }
  @media print {
    #screen {
      padding: 0;
    }
    #container {
      margin: 0;
      padding: 0;
      width: 100%;
      border: none;
    }
    .hide-print {
      display: none;
    }
    @page {
      margin: 0.5rem;
      size: 3.5in 3.5in;
      size: portrait;
    }
  }
</style>
