<script lang="ts">
  import { replace } from "svelte-spa-router";
  import Layout from "../common/Layout.svelte";
  import { connection, loggedInUserId, store } from "../stores/store";
  import HistoryTemplate from "../templates/HistoryTemplate.svelte";

  let data;

  $: idStore =
    $store && $store.id != undefined
      ? $store.id
      : $store && $store._id != undefined
      ? $store._id
      : null;

  $: if (idStore == null) {
    replace("/");
  }

  $: if ($loggedInUserId && $store) {
    $connection
      .call("transactions.get.history.forPOS", idStore, "", "all", new Date())
      .then((items) => {
        data = items;
      })
      .catch((err) => {
        globalThis.pushToast(err.reason, "error");

        if (err.reason == "Login Required") {
          replace("/");
        }
      });
  }

  const handleSearch = (e) => {
    const { keyword, date, priceType } = e.detail;
    $connection
      .call(
        "transactions.get.history.forPOS",
        idStore,
        keyword,
        priceType,
        new Date(date)
      )
      .then((items) => {
        data = items;
      })
      .catch((err) => {
        globalThis.pushToast(err.reason, "error");
      });
  };
</script>

{#if $loggedInUserId != undefined && idStore != undefined}
  <Layout>
    <HistoryTemplate items={data} on:search-change={handleSearch} />
  </Layout>
{/if}
