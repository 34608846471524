<script>
	import { onMount } from "svelte";
	import { temporders as tempOr } from "../stores/store";

	let items = [];
	let show = false;

	onMount(() => {
		items = $tempOr;
		if (items.length > 0) {
			show = true;
		}
	});

	setInterval(async () => {
		console.log("-- loop notif");
		items = $tempOr;

		if (items.length > 0) {
			show = true;
		} else {
			show = false;
		}
	}, 300000);
</script>

{#if show}
	<div
		class="absolute top-5 right-0 left-0 z-10 p-4 bg-red-500 mx-auto rounded-md shadow-md w-1/2 flex justify-between  items-center"
	>
		<p class="text-md text-white font-medium"
			>Oops! You still have {items.length} Transaction on Local!</p
		>
		<div
			class="cursor-pointer"
			on:click|preventDefault="{() => {
				show = !show;
			}}"
		>
			<svg
				class="fill-current text-white dark:text-white"
				xmlns="http://www.w3.org/2000/svg"
				width="18"
				height="18"
				viewBox="0 0 18 18"
			>
				<path
					d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
				></path>
			</svg></div
		>
	</div>
{/if}
