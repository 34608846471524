import Utility from "../common/Util/Utility";
import { db } from "../config";
import { userActionTitle } from "../enums/action";
export default {
    async addUserAction(actionTitle, userId, data) {
        await db.userActions.add({
            action: actionTitle,
            createdAt: new Date(),
            createdBy: userId,
            data: data,
        });
        if ([userActionTitle.ProcessTransactionWhenOnline, userActionTitle.ProcessTransactionWhenOffline].includes(actionTitle)) {
            await this.deleteOldRecords();
        }
    },
    async deleteOldRecords() {
        const oneWeekAgo = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000); // 1 week in milliseconds
        try {
            await db.userActions.where("createdAt").below(oneWeekAgo).delete();
            console.log("Old records (older than 1 week) have been deleted.");
        }
        catch (error) {
            console.error("Error deleting old records:", error);
        }
    },
    async exportDataToTextFile() {
        try {
            // Fetch data from Dexie
            const userActions = await db.userActions.toArray();
            // Format the data as text
            let textContent = "User Actions Log\n\n";
            userActions.forEach((action) => {
                textContent += `Action: ${action.action}\n`;
                textContent += `Created At: ${Utility.formatDate(action.createdAt, "long-detail")}\n`;
                textContent += `Created By: ${action.createdBy}\n`;
                textContent += `Data: ${JSON.stringify(action.data)}\n`; // Converts data to a compact string format
                textContent += `\n---\n\n`; // Separator between entries
            });
            // Create a Blob and trigger download
            const blob = new Blob([textContent], { type: "text/plain" });
            const url = URL.createObjectURL(blob);
            // Create a download link and click it programmatically
            const link = document.createElement("a");
            link.href = url;
            link.download = "user_actions_log.txt";
            document.body.appendChild(link);
            link.click();
            // Clean up
            document.body.removeChild(link);
            URL.revokeObjectURL(url);
            console.log("User actions have been exported to user_actions_log.txt");
        }
        catch (error) {
            console.error("Error exporting data:", error);
        }
    },
};
