<script>
	import { createEventDispatcher, onMount } from "svelte";
	import { form as Form } from "svelte-forms";
	import Modal from "../Modal.svelte";
	import Button from "../control/Button.svelte";
	import TextArea from "../control/TextArea.svelte";
	import PaymentTypeList from "../PaymentTypeList.svelte";
	import Utility from "../Util/Utility";
	import { connection, paymentTypes, store, serverStatus, inetStatus } from "../../stores/store";
	import { transactionStatus } from "../../enums/transaction";
	import { actionOrigin } from "../../enums/action";

	let changePaymentTypeRemark = "";
	let formValid = true;
	let modalChangePaymentType;
	let newPaymentDetail = {
		name: "",
		numberCard: null,
		cvv: null,
		expDate: null,
	};
	let newPaymentType = "";
	let paymentTypeListRef;
	export let data;

	onMount(async () => {
		await loadPaymentTypes();
	});

	const loadPaymentTypes = async () => {
		// check is online
		if ($serverStatus && $inetStatus) {
			const res = await $connection.call("paymentTypes.forList");
			let sortedPaymentType = Utility.sortPaymentTypeByCreatedAt(res);
			paymentTypes.set(sortedPaymentType);
		}
		// if offline will using $paymentTypes just as it is
	};

	const dispatch = createEventDispatcher();
	const formChangePaymenType = Form(
		() => ({
			changePaymentTypeRemark: { value: changePaymentTypeRemark, validators: ["required"] },
			newPaymentType: { value: newPaymentType, validators: ["required"] },
		}),
		{
			initCheck: false,
			validateOnChange: false,
			stopAtFirstError: false,
			stopAtFirstFieldError: false,
		}
	);

	export function open() {
		modalChangePaymentType.toggle();
	}

	export function close() {
		modalChangePaymentType.close();
	}
	function resetVariableValue() {
		changePaymentTypeRemark = "";
		formValid = true;
		newPaymentDetail = {
			name: "",
			numberCard: null,
			cvv: null,
			expDate: null,
		};
		newPaymentType = "";
	}
	const validatePaymentType = async (storeId) => {
		const isValid = await $connection.call("stores.validateCartAndPaymentType.forPOS", {
			storeId: storeId,
			from: actionOrigin.ChangePaymentType,
			paymentType: newPaymentType,
		});
		if (!isValid.success) {
			if (isValid.error == "object-not-valid") {
				globalThis.pushToast("Sorry! Please try again in few seconds", "error");
				return false;
			}

			if (isValid.error == "payment-type-inactive") {
				globalThis.pushToast("Payment Method is Inactive", "error");
				return false;
			}
		}
		return true;
	};
</script>

<Modal
	index={20}
	title="CHANGE PAYMENT TYPE"
	closed
	on:close={() => {
		resetVariableValue();
		dispatch("close");
	}}
	bind:this={modalChangePaymentType}
>
	<div class="w-full flex flex-col gap-4">
		<div class="flex-1 p-4 relative border border-gray-200 bg-white shadow-md rounded-tl-lg rounded-bl-lg cursor-pointer hover:bg-gray-100">
			<div class="flex justify-between items-center">
				<h4 class="text-md font-medium text-gray-800">
					Transaction #{data["code"].substring(0, 8).toUpperCase()}
					{#if data["ojol"]}
						<span class="bg-green-500 rounded-md px-2 py-1 text-white text-xs"> OJOL </span>
					{/if}

					{#if data["employeeId"] == "BJWMOBILE"}
						<span class="bg-blue-500 rounded-md px-2 py-1 text-white text-xs"> MOBILE </span>
					{/if}
				</h4>
				<span class="text-sm text-gray-500">{Utility.formatDate(new Date(data["createdAtOnLocal"]), "long-detail")}</span>
			</div>
			<div class="flex justify-between items-center">
				<h4 class="text-sm text-gray-800">
					Order No: <span class="font-medium">{data["orderNo"] ? data["orderNo"] : "-"}</span>
				</h4>
			</div>
			<div class="flex justify-between items-center">
				<h4 class="text-sm text-gray-800">
					Status: <span class="font-medium {data['status'] != transactionStatus.Success ? 'text-red-500' : 'text-green-500'}"
						>{data["status"]}</span
					>
				</h4>
			</div>
			<div class="flex justify-between items-center">
				<h4 class="text-sm text-gray-800">
					Payment Type: <span class="font-medium">
						{Utility.getPaymentTypeNameByCode($paymentTypes, data["paymentType"])}
					</span>
				</h4>
			</div>
			<div class="flex space-x-2 items-center">
				<i class="bx bx-user" />
				<h4 class="text-sm text-gray-800">
					{data["customer"]["name"] || "-"}
				</h4>
			</div>
			<div class="flex space-x-2 items-center">
				<i class="bx bx-phone" />
				<h4 class="text-sm text-gray-800">
					{data["customer"]["phone"] || "-"}
				</h4>
			</div>
			<div class="flex space-x-2 items-center">
				<i class="bx bx-chair" />
				<h4 class="text-sm text-gray-800">
					{data["customer"]["table"] || "-"}
				</h4>
			</div>
			<p class="absolute right-4 bottom-4 text-lg text-gray-800 font-medium m-0">
				{Utility.formatRupiah(data["total"], "Rp. ")}
			</p>
		</div>
		<PaymentTypeList
			bind:this={paymentTypeListRef}
			moreExcludedPaymentType={[data["paymentType"]]}
			bind:paymentType={newPaymentType}
			bind:paymentDetail={newPaymentDetail}
			title={"New Payment Type"}
			titleColor={!newPaymentType && !formValid ? "text-red-500" : "text-dark-second"}
			hasDefaultPaymentType={true}
		/>
		<div>
			<TextArea
				form={formChangePaymenType}
				name="changePaymentTypeRemark"
				label="CHANGE PAYMENT TYPE REMARKS"
				maxlength={200}
				bind:value={changePaymentTypeRemark}
				placeholder=""
			/>
		</div>
		<div class="flex justify-between items-center gap-5">
			<Button
				extClass="flex-1 font-medium tracking-wide"
				status="gray"
				on:click={() => {
					resetVariableValue();
					dispatch("close");
				}}
				size="large">CANCEL</Button
			>
			<Button
				extClass="flex-1 font-medium tracking-wide"
				on:click={async () => {
					formChangePaymenType.validate();
					formValid = $formChangePaymenType.valid;
					if (formValid) {
						if (changePaymentTypeRemark.length < 11) {
							globalThis.pushToast("Sorry! Min 11 char Remarks Change Payment Type.", "error");
							return;
						}
						if ($serverStatus && $inetStatus) {
							const storeId = $store.id || $store._id;
							const isPaymentTypeValid = await validatePaymentType(storeId);
							if (!isPaymentTypeValid) {
								paymentTypeListRef.refreshData();
								return;
							}
							dispatch("submit", {
								transactionId: data["_id"],
								storeId,
								changePaymentTypeRemark,
								newPaymentType,
								newPaymentDetail,
							});
							resetVariableValue();
						} else {
							globalThis.pushToast("Oops! Server is currently offline. Please check your connection and try again ", "error");
							return;
						}
					} else globalThis.pushToast("Please fill out the required fields.", "error");
				}}
				size="large">SAVE</Button
			>
		</div>
	</div>
</Modal>
